import { FirebaseDynamicLinksTypes } from '@react-native-firebase/dynamic-links';
import { IToastProps, Toast, useToast } from 'native-base';
import QueryString from 'query-string';
import { useCallback, useEffect } from 'react';
import { Linking, Platform } from 'react-native';

import { Locale } from 'app/generated/hygraph';
import useGuestDataQuery from 'app/hooks/useGuestDataQuery';
import useStore, { AuthMode, VerificationState } from 'app/hooks/useStore';
import * as navigation from 'app/navigation/navigationHelper';
import { VerificationMessageContent } from 'app/providers/AuthProvider/AuthProvider.types';
import { useI18n } from 'app/providers/I18nProvider';
import Analytics, { Events } from 'app/services/Analytics';
import { auth, dynamicLinks } from 'app/services/Firebase';
import { getAuthTokenValidation } from 'app/services/GuestCenterService';
import { AuthTokenActionCode } from 'app/services/GuestCenterService.types';
import { TranslationHelper } from 'app/services/I18n';
import Logger from 'app/services/Logger';
import { isDynamicLinkLink } from 'app/utils/validation';

const MODULE = '[useLinkHandling]';

/**
 * Handles incoming dynamic (or direct web) links and routes them to the
 * correct handler.
 *
 * IMPORTANT: only should be used once in <Main/>
 */
export default function useLinkHandling() {
  const { t, setLocale, locale } = useI18n();
  const { setMessage } = useStore((s) => ({
    setMessage: s.setVerificationMessage,
  }));
  const { remove: clearUserData, refetch: userDataRefetch } = useGuestDataQuery();
  const raiseToast = useCallback((props: IToastProps, suppressAlerts?: boolean) => {
    if (!suppressAlerts) Toast.show(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const linkHandler = (
      link: Maybe<FirebaseDynamicLinksTypes.DynamicLink>,
      type: 'background' | 'foreground'
    ) => {
      Logger.debug(`[useLinkHandling] received ${type} link`, { link });
      if (link?.url) {
        routeLink({
          link,
          toast: Toast,
          raiseToast,
          clearUserData,
          locale,
          setLocale,
          setMessage,
          userDataRefetch,
          t,
        });
      }
    };

    if (Platform.OS === 'web') {
      linkHandler(
        {
          url: window.location.href,
          minimumAppVersion: null,
          utmParameters: {},
        },
        'foreground'
      );
      return undefined;
    }

    Linking.getInitialURL().then((link) => {
      if (link) {
        linkHandler({ url: link, minimumAppVersion: 0, utmParameters: { '': '' } }, 'background');
      }
    });

    dynamicLinks()
      .getInitialLink()
      .then((link) => linkHandler(link, 'background'))
      .catch((error) => {
        Logger.error(`[useLinkHandling] error processing incoming background dynamic link`, {
          error,
        });
      });

    const unsubscribe = dynamicLinks().onLink((link) => linkHandler(link, 'foreground'));

    return () => {
      Logger.debug('[useLinkHandling] unsubscribe foreground handler');
      unsubscribe();
    };

    // only want this to fire ONCE on mount
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}

type HandleLinkRoutingProps = {
  link: FirebaseDynamicLinksTypes.DynamicLink | string;
  suppressAlerts?: boolean;
  goToAuthScreen?: boolean;
  toast: ReturnType<typeof useToast>;
  raiseToast: (p: IToastProps, suppressAlerts?: boolean) => void;
  clearUserData: () => void;
  locale: Locale;
  setLocale: (locale: Locale) => void;
  setMessage: (message: VerificationMessageContent) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userDataRefetch: () => Promise<any>;
  t: TranslationHelper;
};

export function routeLink({
  link,
  suppressAlerts = false,
  goToAuthScreen = true,
  toast,
  raiseToast,
  clearUserData,
  locale,
  setLocale,
  setMessage,
  userDataRefetch,
  t,
}: HandleLinkRoutingProps) {
  const url = typeof link === 'string' ? link : link.url;
  const { query, fragmentIdentifier } = QueryString.parseUrl(url, {
    parseFragmentIdentifier: true,
  });
  const hash = QueryString.parse(fragmentIdentifier ?? '');
  const { mode, continueUrl, lang, tpe } = query;
  const { email, orderAuthToken, emailReservingFor } = useStore.getState();
  const orderToken = (hash.order_token || query.order_token || '').toString();

  Logger.info(`${MODULE} handling link routing`, {
    url,
    query,
    hash,
    email,
    continueUrl,
    lang,
  });

  let urlEmail = tpe as string;
  if (!urlEmail && continueUrl) {
    const decoded = decodeURI(continueUrl as string);
    const { query: continueQuery } = QueryString.parseUrl(decoded);
    const { tpe: continueTpe } = continueQuery;
    if (continueTpe) {
      urlEmail = continueTpe as string;
    }
  }

  if (lang) {
    try {
      const userLang = lang as Locale;
      if (locale === Locale.En) {
        // only set when we are running in English
        setLocale(userLang);
      }
    } catch {
      Logger.info(`${MODULE} unable to set language to ${lang}`);
    }
  }

  if (mode === 'signIn') {
    Logger.info(`${MODULE} sign in flow triggered`);
    handleSignInUser({
      signInUrl: url,
      email,
      urlEmail,
      emailReservingFor,
      orderAuthToken,
      suppressAlerts,
      setMessage,
      raiseToast,
      clearUserData,
      t,
    });
  } else if (orderToken) {
    Logger.info(`${MODULE} token flow triggered`);
    handleTokenValidation({
      orderToken,
      verifiedEmail: email,
      suppressAlerts,
      goToAuthScreen,
      toast,
      raiseToast,
      clearUserData,
      userDataRefetch,
      t,
    });
  } else if (validManualLink(url) || validManualLink(query.link) || validManualLink(query.u)) {
    let resolvedLink: Maybe<string> = null;
    dynamicLinks()
      .resolveLink(url)
      .then((value) => {
        resolvedLink = value.url;
      })
      .catch((err) => {
        resolvedLink = query.link?.toString() ?? query.u?.toString() ?? null;
      })
      .finally(() => {
        if (resolvedLink) {
          routeLink({
            link: resolvedLink,
            suppressAlerts,
            goToAuthScreen,
            toast,
            raiseToast,
            clearUserData,
            locale,
            setLocale,
            setMessage,
            userDataRefetch,
            t,
          });
        }
      });
  }
}

export async function handleOrderAuthToken(
  orderAuthToken: Maybe<string>,
  email: Maybe<string>,
  clearUserData: () => void
) {
  const { setAuthMode, setOrderAuthToken } = useStore.getState();

  if (orderAuthToken && email) {
    await getAuthTokenValidation(orderAuthToken, email, null).then((tokenValidation) => {
      if (tokenValidation?.data?.success) {
        setAuthMode(AuthMode.Unset);
        setOrderAuthToken(null);
        clearUserData();
      }
    });
  } else {
    setAuthMode(AuthMode.Unset);
    clearUserData();
  }
}

function validManualLink(queryParam: string | (string | null)[] | null) {
  return queryParam && isDynamicLinkLink(queryParam.toString());
}

async function handleTokenValidation({
  orderToken,
  verifiedEmail,
  suppressAlerts = false,
  goToAuthScreen = true,
  toast,
  raiseToast,
  clearUserData,
  userDataRefetch,
  t,
}: {
  orderToken: string;
  verifiedEmail: Maybe<string>;
  t: TranslationHelper;
} & Pick<
  HandleLinkRoutingProps,
  'toast' | 'raiseToast' | 'clearUserData' | 'userDataRefetch' | 'suppressAlerts' | 'goToAuthScreen'
>) {
  const {
    setEmail,
    setVerificationState,
    setVerificationProcessing,
    setAuthMode,
    setEntitlementKey,
    setOrderAuthToken,
    setProduct,
  } = useStore.getState();
  const toastId = `auth_message_toast${Date.now()}`;

  navigation.navigate('Authentication');

  setVerificationProcessing(true);
  setOrderAuthToken(orderToken);
  Logger.debug(`${MODULE} starting token validation`, { orderToken, verifiedEmail });

  await getAuthTokenValidation(orderToken, verifiedEmail, await getBearerToken())
    .then(async (tokenValidation) => {
      if (tokenValidation?.data?.success) {
        const { data } = tokenValidation;
        toast.close(toastId);

        if (data.actionCode === AuthTokenActionCode.ReserveOnBehalfOf) {
          setEntitlementKey(data.locator);
          setProduct(null);
          setEmail(data.email);
          setVerificationState(VerificationState.Verified);
          clearUserData();
          raiseToast(
            {
              title: t('app_sign_in_retrieving_data_title'),
              description: t('app_sign_in_retrieving_description', {
                guestEmail: data.email,
              }),
            },
            suppressAlerts
          );
          await userDataRefetch().then((result) => {
            if (result?.isSuccess) {
              raiseToast(
                {
                  title: t('app_sign_in_retrieved_title'),
                  description: t('app_sign_in_retrieved_description', {
                    cityPassEmail: data.cityPassEmail ?? '',
                    guestEmail: data.email,
                  }),
                },
                suppressAlerts
              );
              navigation.navigate('Authentication');
            }
          });
        } else if (data.actionCode === AuthTokenActionCode.SignIn) {
          if (!data.token) return;

          // Sign out the old user
          if (auth().currentUser) {
            const old = auth().currentUser?.email;
            await auth()
              .signOut()
              .then(() => {
                Logger.debug('signed out old user', {
                  title: t('app_sign_out_title'),
                  description: t('app_sign_out_description', {
                    email: old ?? '',
                  }),
                });
              });
            clearUserData();
          }
          // sign in the new user
          await auth()
            .signInWithCustomToken(data.token)
            .then(() => {
              // set the email we got back from validation and clear the user data
              setEmail(auth().currentUser?.email ?? null);
              Analytics.trackEvent(Events.AuthEmailVerified);
              setVerificationState(VerificationState.Verified);
              Logger.debug('signed in new user', {
                title: t('app_sign_in_title'),
                description: t('app_sign_in_description', {
                  email: auth().currentUser?.email ?? '',
                }),
              });
            })
            .then(() => {
              // stay on welcome screen until user chooses to advance
              setEntitlementKey(data.locator);
              if (
                Platform.OS === 'web' ||
                (useStore.getState().hasCompletedWelcome && goToAuthScreen)
              ) {
                userDataRefetch();
              }
            })
            .catch((reason) => {
              Logger.warn(`${MODULE} failed sign in`, { reason });
              raiseToast(
                {
                  title: t('app_sign_in_failed_title'),
                  description: `${reason}...`,
                },
                suppressAlerts
              );
              Analytics.trackEvent(Events.AuthEmailVerificationFailed);
            })
            .finally(() => {
              toast.close(toastId);
            });
        } else if (data.actionCode === AuthTokenActionCode.VerifyEmail) {
          // put the user through the auth flow to gather / verify the email sign in flow
          // will then need to update email on the server side at the end and that can happen
          // in both the verify email flow (handleVerifyEmail) or the sign in flow (handleSignInUser)
          // depending on if the user is new or already exists
          clearUserData();
          setVerificationState(VerificationState.Unverified);
          setAuthMode(AuthMode.TokenVerifyEmail);
          toast.close(toastId);
          setVerificationProcessing(false);
          navigation.navigate('Authentication', {
            screen: 'AuthenticationStepEnterEmail',
          });
        }
      }
    })
    .catch((error) => {
      setOrderAuthToken(null);
      if (error?.response?.actionCode === AuthTokenActionCode.SignInAsCitypassUser) {
        Logger.error(`${MODULE} failed to reserve on behalf of`, { error });
        raiseToast(
          {
            title: t('app_token_verification_failed_title'),
            description: t('app_token_verification_failed_description'),
            duration: null,
            bgColor: 'purple.600',
            placement: 'top',
          },
          suppressAlerts
        );
      }
    })
    .finally(() => {
      try {
        toast.close(toastId);
        // eslint-disable-next-line no-empty
      } catch (error) {}
      Logger.debug(`${MODULE} finished token validation`);
      setVerificationProcessing(false);
    });
}

async function handleSignInUser({
  signInUrl,
  email,
  urlEmail,
  emailReservingFor,
  orderAuthToken,
  setMessage,
  clearUserData,
  t,
}: {
  signInUrl: string;
  email: Maybe<string>;
  urlEmail: Maybe<string>;
  emailReservingFor: Maybe<string>;
  orderAuthToken: Maybe<string>;
  t: TranslationHelper;
} & Pick<
  HandleLinkRoutingProps,
  'setMessage' | 'raiseToast' | 'clearUserData' | 'suppressAlerts'
>) {
  const { setAuthMode, setEmail, setVerificationState, setVerificationProcessing } =
    useStore.getState();
  Logger.info(`${MODULE} sign in email details ${email} / ${emailReservingFor}, / ${urlEmail}`);

  const isSignInLink = auth().isSignInWithEmailLink(signInUrl);
  const emailFromSignInLink = isSignInLink && !email && urlEmail; // this is where we circumvent the auth model and provide the user with a one time sign in link that is good across app instances
  if (email || emailFromSignInLink) {
    const startUid = auth().currentUser?.uid;
    Logger.info(`${MODULE} start sign in`, { startUid, email, emailReservingFor });

    if (emailFromSignInLink) {
      setEmail(urlEmail);
      setAuthMode(AuthMode.Unset);
    }

    if (auth().isSignInWithEmailLink(signInUrl)) {
      navigation.navigate('Authentication');
      Logger.info(`${MODULE} setting processing`);
      setVerificationProcessing(true);
      Logger.info(`${MODULE} set processing`);

      await auth()
        .signInWithEmailLink(email ?? (urlEmail as string), signInUrl)
        .then(async (userCredentials) => {
          const user = auth().currentUser;
          if (user && userCredentials && userCredentials.user && user.emailVerified) {
            Logger.info(`${MODULE} signed in user`, { email: userCredentials.user.email });
            setVerificationState(VerificationState.Verified);
            Analytics.trackEvent(Events.AuthEmailVerified);
            setEmail(userCredentials.user.email);

            // Token Flow: close the loop on updating the email address for tokens representing a travel party that had no email set
            await handleOrderAuthToken(orderAuthToken, user.email, clearUserData);
          }
        })
        .catch((error) => {
          const msg = `${MODULE} sign in email: error processing sign in link.  Possibly add ability to trigger another sign in email here.`;
          setMessage({
            title: t('app_sign_in_failed_title'),
            description: getSignInFailureErrorMessage(t, error),
            status: 'error',
          });
          Analytics.trackEvent(Events.AuthEmailVerificationFailed);
        })
        .finally(() => {
          setVerificationProcessing(false);
        });
    } else {
      const msg = `${MODULE} sign in email: Invalid sign in link.  Possibly add ability to trigger another sign in email here.`;
      setMessage({
        title: t('app_sign_in_failed_title'),
        description: t('app_sign_in_failed_invalid_link'),
        status: 'error',
      });
    }
  } else {
    // this flow could occur if a user triggers a sign in from another device when they already have an account
    // and then use the sign in link on another device to sign in, e.g. they use the app last year on their old phone, trigger a sign
    // in today on their desktop and then use the sign in link on their new phone
    setMessage({
      title: t('app_sign_in_triggered_title'),
      description: t('app_sign_in_gathering_email'),
    });

    // put them through a flow to gather the email and then sign them in afterwards
    setAuthMode(AuthMode.SignInGatherEmail);
    navigation.navigate('Authentication', {
      screen: 'AuthenticationEnterEmailScreen',
    });
  }
}

function getSignInFailureErrorMessage(t: TranslationHelper, error: any) {
  try {
    const code = error?.code;

    switch (code) {
      case 'auth/invalid-email':
        return t('app_sign_in_failed_invalid_email_in_link');
      case 'auth/invalid-action-code':
        return t('app_sign_in_failed_link_already_used');
      default:
        return t('app_sign_in_failed_invalid_link');
    }
  } catch {
    return t('app_sign_in_failed_invalid_link');
  }
}

// sometimes firebase is behind us so we wait for user state change before fetching token
async function getBearerToken() {
  const bearerToken = await auth().currentUser?.getIdToken();
  if (bearerToken) return bearerToken;

  if (Platform.OS !== 'web' || !useStore.getState().email) return null;

  await new Promise<void>((resolve, reject) => {
    if (auth().currentUser) {
      resolve();
      return;
    }

    const userTimeout = setTimeout(() => {
      Logger.warn('getBearerToken() timed out waiting for user');
      resolve();
    }, 10000);
    const unsubscribe = auth().onAuthStateChanged((currentUser) => {
      Logger.debug('getBearerToken()', { currentUser });
      if (currentUser) {
        clearTimeout(userTimeout);
        unsubscribe();
        resolve();
      }
    });
  });

  const $bearerToken = await auth().currentUser?.getIdToken();
  if (!$bearerToken) {
    Logger.warn(`${MODULE} expected bearer token but received none`, {
      currentUser: auth().currentUser,
    });
  }

  return $bearerToken ?? null;
}
