import { BottomTabScreenProps, createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { CompositeScreenProps, NavigatorScreenParams, useRoute } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Circle, Flex, Pressable, Spinner } from 'native-base';
import React, { lazy, ReactNode, useEffect, useRef } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { shallow } from 'zustand/shallow';

import Menu from 'app/components/icons/Menu';
import QRTicket from 'app/components/icons/QRTicket';
import RemoteSVG from 'app/components/RemoteSVG';
import TicketsInfoPopover from 'app/components/TicketsInfoPopover';
import useGuest from 'app/hooks/useGuest';
import useProduct from 'app/hooks/useProduct';
import useStore from 'app/hooks/useStore';
import type { AttractionTicketsStackParamList } from 'app/navigation/AttractionTicketsStackNavigator';
import { findProductId, getRouteParam } from 'app/navigation/navigationHelper';
import type { ProductStackParamList } from 'app/navigation/ProductStackNavigator';
import ProductStackNavigator from 'app/navigation/ProductStackNavigator';
import type { RootStackParamList } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';
import MenuScreen from 'app/screens/MenuScreen';
import Analytics, { Events } from 'app/services/Analytics';
import { tID } from 'app/services/TestHelper';

export type ProductTabScreenProps = BottomTabScreenProps<RootStackParamList, 'Tabs'>;

export type ProductTabParamList = {
  ProductStack: NavigatorScreenParams<ProductStackParamList> | undefined;
  AttractionTicketsStack: NavigatorScreenParams<AttractionTicketsStackParamList>;
  Menu: undefined;
};

export type ProductTabScreenCompositeProps = CompositeScreenProps<
  BottomTabScreenProps<ProductTabParamList, 'ProductStack'>,
  NativeStackScreenProps<RootStackParamList>
>;

/* eslint-disable @typescript-eslint/ban-ts-comment */
const AttractionTicketsStackNavigator = lazy(
  // @ts-expect-error: needs module=Node16/ESNext but dont want to play with that right before launch
  () => import('app/navigation/AttractionTicketsStackNavigator')
);
/* eslint-enable @typescript-eslint/ban-ts-comment */

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<ProductTabParamList>();
const ICON_SIZE = 26;
const TAB_BAR_PADDING = 10;

export default function ProductTabNavigator({ navigation }: ProductTabScreenProps) {
  const { t } = useI18n();
  const route = useRoute();
  const [storeProductId, setProduct] = useStore((s) => [s.productId, s.setProduct], shallow);

  const navProductId = useRef<string>();
  let routeProductId = getRouteParam(route, 'productId');
  let productContentKey = routeProductId ?? storeProductId ?? '';

  /**
   * There are a couple scenarios where the productId route param is not set here,
   *
   * 1) web deep link
   *   - no route.params.productId
   *   - depending on the nested route it might be buried in the navigation state
   *   - should use route productId if found
   *   - examples:
   *     - /product/new-york
   *     - /product/new-york/attractions
   *     - /product/new-york/attraction/empire
   *
   * 2) state.productId is set and native app is opened from inactive state OR web app
   * is opened on root /
   *   - no route.params.productId
   *   - state.productId is set
   *   - should use state.productId
   *   - examples:
   *     - /
   *     - on device, force close app and open from home screen
   */
  if (!routeProductId) {
    navProductId.current = findProductId(navigation.getState());

    if (navProductId.current) {
      routeProductId = navProductId.current;
      productContentKey = navProductId.current;
    } else if (storeProductId) {
      productContentKey = storeProductId;
      navigation.navigate('Tabs', {
        screen: 'ProductStack',
        params: {
          screen: 'Product',
          params: { productId: storeProductId },
        },
      });
    } else {
      navigation.navigate('Destinations');
    }
  }

  const { productContent } = useProduct({ productContentKey });
  const { activeEntitlement, hasActiveEntitlementFor } = useGuest();
  const { bottom } = useSafeAreaInsets();

  const routeParams = { productId: productContentKey };
  const entitlement = hasActiveEntitlementFor(productContentKey) ? activeEntitlement : undefined;

  // update the store productId if we're deep linking to a new product
  // can't update params in the render function
  useEffect(() => {
    if (routeProductId && routeProductId !== storeProductId) {
      setProduct(routeProductId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeProductId]);

  useEffect(() => {
    if (routeProductId && routeProductId !== storeProductId) {
      setProduct(routeProductId);
    }
    if (!routeProductId && navProductId.current)
      // TODO figure out types
      navigation.setParams({ productId: navProductId.current } as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BottomTab.Navigator
      initialRouteName="ProductStack"
      screenOptions={{
        headerShadowVisible: true,
        tabBarStyle: {
          height: 70 + bottom,
          paddingTop: TAB_BAR_PADDING,
          paddingBottom: TAB_BAR_PADDING + bottom,
          elevation: 24,
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 0,
          },
          shadowOpacity: 0.25,
          shadowRadius: 10,
        },
        tabBarLabelStyle: {
          paddingTop: 5,
        },
        tabBarLabelPosition: 'below-icon',
        tabBarInactiveTintColor: '#373737',
      }}
    >
      <BottomTab.Screen
        component={ProductStackNavigator}
        initialParams={{
          screen: 'Product',
          params: { productId: productContentKey },
        }}
        name="ProductStack"
        options={({ navigation: $navigation }) => ({
          title: t<string>(entitlement ? 'app_product_tab_home_auth' : 'app_product_tab_home'),
          tabBarTestID: tID('tab-product'),
          tabBarButton: ({ children }) => (
            <TabBarButton
              analyticsEvent={Events.NavProductHome}
              navigation={$navigation}
              routeParams={routeParams}
              screen="ProductStack"
            >
              {children}
            </TabBarButton>
          ),
          headerShown: false,
          tabBarIconStyle: {
            alignSelf: 'stretch', // this maintains height for dynamic width for cticket icons
          },
          tabBarIcon: ({ color, size }) => (
            <Flex alignItems="center" height={`${size}px`} width="full">
              <RemoteSVG
                color={color}
                fallback={
                  !productContent ? <Spinner size="sm" /> : <Circle bgColor={color} size={size} />
                }
                style={{ flex: 1 }}
                uri={productContent?.logoSpotOutline?.url ?? ''}
              />
            </Flex>
          ),
        })}
      />
      <BottomTab.Screen
        component={AttractionTicketsStackNavigator}
        initialParams={{
          screen: 'AttractionTickets',
          params: { productId: productContentKey },
        }}
        name="AttractionTicketsStack"
        options={({ navigation: $navigation }) => ({
          title: t<string>(
            entitlement ? 'app_product_tab_tickets_auth' : 'app_product_tab_tickets'
          ),
          tabBarTestID: tID('tab-attraction-tickets'),
          headerShown: false,
          tabBarIcon: ({ color }) => (
            <>
              <TicketsInfoPopover />
              <QRTicket color={color} size={ICON_SIZE} />
            </>
          ),
          tabBarButton: ({ children }) => (
            <TabBarButton
              analyticsEvent={Events.NavTickets}
              navigation={$navigation}
              routeParams={routeParams}
              screen="AttractionTicketsStack"
            >
              {children}
            </TabBarButton>
          ),
          freezeOnBlur: true,
        })}
      />
      <BottomTab.Screen
        component={MenuScreen}
        name="Menu"
        options={({ navigation: $navigation }) => ({
          title: t<string>('app_product_tab_menu'),
          tabBarTestID: tID('tab-menu'),
          tabBarIcon: ({ color }) => <Menu color={color} size={20} />,
          tabBarButton: ({ children }) => (
            <TabBarButton
              analyticsEvent={Events.NavMore}
              navigation={$navigation}
              routeParams={routeParams}
              screen="Menu"
            >
              {children}
            </TabBarButton>
          ),
        })}
      />
    </BottomTab.Navigator>
  );
}

function TabBarButton({
  navigation,
  screen,
  routeParams,
  analyticsEvent,
  children,
}: {
  children?: ReactNode;
  screen: keyof ProductTabParamList;
  routeParams: Record<string, string>;
  navigation: {
    jumpTo: (screen: keyof ProductTabParamList, params?: Record<string, string>) => void;
  };
  analyticsEvent: Events;
}) {
  return (
    <Pressable
      flex="1"
      onPress={() => {
        navigation.jumpTo(screen, routeParams);
        Analytics.trackEvent(analyticsEvent, routeParams);
      }}
    >
      {children}
    </Pressable>
  );
}
