import dayjs from 'dayjs';
import memoize from 'lodash/memoize';
import { useMemo } from 'react';
import { UseQueryOptions } from 'react-query';

import { GetProductQuery, useGetProductQuery } from 'app/generated/hygraph';
import useConfiguration from 'app/hooks/useConfiguration';
import { useI18n } from 'app/providers/I18nProvider';
import { processAttractions } from 'app/services/AttractionHelper';
import HygraphService from 'app/services/HygraphService';
import { createAppProductLineup, getDefaultProductLineup } from 'app/services/ProductLineupHelper';

const { client } = HygraphService();

export type UseProductResult = ReturnType<typeof useProduct>;
export type ProductContent = NonNullable<UseProductResult['productContent']>;

const mapQueryToProductContent = memoize(
  ({
    rawProductContent,
    productContentKey,
  }: {
    rawProductContent: GetProductQuery | undefined;
    productContentKey: string;
  }) => {
    if (!rawProductContent) return undefined;
    const { product } = rawProductContent;
    const attractions = processAttractions({
      attractions: product?.attractions ?? [],
      productId: productContentKey,
    });

    return {
      ...product,
      attractions,
    };
  },
  (...args) => JSON.stringify(args)
);

export default function useProduct({
  productContentKey,
  ...queryOpts
}: { productContentKey: string } & UseQueryOptions<GetProductQuery>) {
  const { locale } = useI18n();
  const { data: configurationResponse } = useConfiguration();
  const { data: rawProductContent, ...useProductContentResult } = useGetProductQuery(
    client,
    {
      productId: productContentKey ?? '',
      locale,
      today: dayjs().format('YYYY-MM-DD'),
    },
    {
      ...queryOpts,
      enabled: !!productContentKey,
    }
  );
  const productContent = useMemo(
    () => mapQueryToProductContent({ rawProductContent, productContentKey }),
    [productContentKey, rawProductContent]
  );
  const defaultProductLineup = useMemo(() => {
    if (!productContentKey) return undefined;

    const productLineup = getDefaultProductLineup({
      productLineups: configurationResponse?.productLineups.configuration,
      productContentKey,
    });

    return createAppProductLineup({
      productLineup,
      productContentLineups: productContent?.attractionLineups,
      entitlement: null,
    });
  }, [
    configurationResponse?.productLineups.configuration,
    productContent?.attractionLineups,
    productContentKey,
  ]);

  return {
    ...useProductContentResult,
    productContent,
    defaultProductLineup,
  };
}
