import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
  NavigatorScreenParams,
  RouteProp,
  Theme as ReactNavigationTheme,
} from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import { HStack, Menu, Pressable, useTheme } from 'native-base';
import React, { lazy, ReactNode, Suspense, useMemo, useRef } from 'react';
import { ColorSchemeName } from 'react-native';

import HeaderStatusBar from 'app/components/HeaderStatusBar';
import Cross from 'app/components/icons/Cross';
import Loading from 'app/components/Loading/Loading';
import withNativeBase from 'app/hocs/withNativeBase';
import useAppInitialRouteName from 'app/hooks/useAppInitialRouteName';
import useHeaderScreenOptions from 'app/hooks/useHeaderScreenOptions/useHeaderScreenOptions';
import type { AuthenticationStackParamList } from 'app/navigation/AuthenticationStackNavigator';
import AuthenticationStackNavigator from 'app/navigation/AuthenticationStackNavigator';
import linking from 'app/navigation/linking';
import { navigationRef } from 'app/navigation/navigationHelper';
import ProductTabNavigator, { ProductTabParamList } from 'app/navigation/ProductTabNavigator';
import { useI18n } from 'app/providers/I18nProvider';
import AppInfoScreen from 'app/screens/AppInfoScreen';
import DestinationsScreen from 'app/screens/DestinationsScreen';
import LanguageScreen from 'app/screens/LanguageScreen';
import MenuScreen from 'app/screens/MenuScreen/MenuScreen';
import NotFoundScreen from 'app/screens/NotFoundScreen';
import NotificationsScreen from 'app/screens/NotificationsScreen';
import PrivacyPolicyScreen from 'app/screens/PrivacyPolicyScreen';
import PurchasesScreen from 'app/screens/PurchasesScreen';
import TermsConditionsScreen from 'app/screens/TermsConditionsScreen';
import Analytics from 'app/services/Analytics';

const LinkPassesScreen = lazy(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error: needs module=Node16/ESNext but dont want to play with that right before launch
  () => import('../../screens/LinkPassesScreen')
);

/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-expect-error: needs module=Node16/ESNext but dont want to play with that right before launch
const DevToolsScreen = lazy(() => import('app/screens/DevToolsScreen'));
// @ts-expect-error: see above
const ReservationCancelScreen = lazy(() => import('app/screens/ReservationCancelScreen'));
// @ts-expect-error: see above
const ReservationFlowScreen = lazy(() => import('app/screens/ReservationFlowScreen'));
/* eslint-enable @typescript-eslint/ban-ts-comment */

export type RootStackParamList = {
  AppInfo: undefined;
  Authentication: NavigatorScreenParams<AuthenticationStackParamList> | undefined;
  Chat: undefined;
  Destinations?: {
    order_token?: string;
  };
  DevTools: undefined;
  Feedback: undefined;
  Language: undefined;
  NotFound: undefined;
  Notifications: undefined;
  PrivacyPolicy: undefined;
  Tabs: NavigatorScreenParams<ProductTabParamList>;
  Profile: undefined;
  Purchases: undefined;
  ReservationFlow?: {
    productKey: string;
    attractionName: string;
    attractionKey: string;
  };
  ReservationCancel: {
    reservationKey: string;
  };
  TermsConditions: undefined;
  Tutorial: undefined;
  Welcome: undefined;
  LinkPasses: undefined;
  Menu: undefined;
};

const Stack = createNativeStackNavigator<RootStackParamList>();

type NavigationContainerOnStateChange = Parameters<typeof NavigationContainer>[0]['onStateChange'];

export default function Navigation({
  colorScheme,
  children,
  before,
}: {
  colorScheme: ColorSchemeName;
  children?: ReactNode;
  before?: ReactNode;
}) {
  const { t } = useI18n();
  const theme = useTheme();
  const initialRouteName = useAppInitialRouteName();
  const routeNameRef = useRef<Maybe<string>>();
  const headerScreenOptions = useHeaderScreenOptions();
  const navTheme: ReactNavigationTheme = useMemo(
    () => ({
      ...DefaultTheme,
      colors: {
        ...DefaultTheme.colors,
        background: theme.colors.white,
        primary: theme.colors.brand.primary,
        text: theme.colors.brand.secondary,
      },
    }),
    [theme.colors]
  );
  const handleStateChange: NavigationContainerOnStateChange = useMemo(() => {
    return (state) => {
      const previousRouteName = routeNameRef.current;
      const currentRouteName = navigationRef?.getCurrentRoute()?.name;

      if (previousRouteName !== currentRouteName) {
        routeNameRef.current = currentRouteName;
        Analytics.onScreenChange(state);
      }
    };
  }, []);
  const handleOnReady = useMemo(() => {
    return () => {
      routeNameRef.current = navigationRef.getCurrentRoute()?.name;
    };
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <NavigationContainer
        ref={navigationRef}
        linking={linking}
        theme={colorScheme === 'dark' ? DarkTheme : navTheme}
        onReady={handleOnReady}
        onStateChange={handleStateChange}
      >
        {before}
        <Stack.Navigator initialRouteName={initialRouteName} screenOptions={headerScreenOptions}>
          <Stack.Screen
            component={DestinationsScreen}
            name="Destinations"
            options={{
              title: t<string>('app_destinations_title'),
              header: () => <HeaderStatusBar />,
            }}
          />
          <Stack.Screen
            component={ProductTabNavigator}
            name="Tabs"
            options={{ headerShown: false }}
          />
          <Stack.Screen
            component={LanguageScreen}
            name="Language"
            options={{ title: t<string>('app_menu_language') }}
          />
          <Stack.Screen
            component={NotificationsScreen}
            name="Notifications"
            options={{ title: t<string>('app_menu_notifications') }}
          />
          <Stack.Screen
            component={PurchasesScreen}
            name="Purchases"
            options={{ title: t<string>('app_menu_my_purchases') }}
          />
          <Stack.Screen
            component={TermsConditionsScreen}
            name="TermsConditions"
            options={{ title: t<string>('app_menu_terms_and_conditions') }}
          />

          <Stack.Screen
            component={PrivacyPolicyScreen}
            name="PrivacyPolicy"
            options={{ title: t<string>('app_menu_privacy_policy') }}
          />

          <Stack.Screen
            component={AppInfoScreen}
            name="AppInfo"
            options={{ title: t<string>('app_menu_app_info') }}
          />

          <Stack.Screen
            component={LinkPassesScreen}
            name="LinkPasses"
            options={{
              title: t<string>('app_auth_tickets_title'),
            }}
          />

          {/* <Stack.Screen
                component={FeedbackScreen}
                name="Feedback"
                options={{ title: t<string>('app_menu_report_an_issue') }}
              /> */}

          {/* <Stack.Screen
              component={LiveChatScreen}
              name="Chat"
              options={{ title: t<string>('app_menu_live_chat') }}
            /> */}

          <Stack.Screen
            component={NotFoundScreen}
            name="NotFound"
            options={{ title: t<string>('app_error_not_found') }}
          />

          <Stack.Screen
            component={AuthenticationStackNavigator}
            name="Authentication"
            options={{ headerShown: false }}
          />

          <Stack.Screen component={MenuScreen} name="Menu" />

          <Stack.Group screenOptions={{ presentation: 'modal', gestureEnabled: false }}>
            <Stack.Screen
              component={withNativeBase(ReservationFlowScreen)}
              name="ReservationFlow"
              options={({ navigation }) => ({
                title: t<string>('app_reservations_title'),
                headerBackVisible: false,
                headerRight: () => (
                  <HStack alignItems="center">
                    <Pressable p="1" onPress={() => navigation.canGoBack() && navigation.goBack()}>
                      <Cross />
                    </Pressable>
                  </HStack>
                ),
              })}
            />
          </Stack.Group>

          <Stack.Group screenOptions={{ presentation: 'modal', gestureEnabled: false }}>
            <Stack.Screen
              component={ReservationCancelScreen}
              name="ReservationCancel"
              options={{
                title: t<string>('app_reservation_cancel_title'),
                headerBackVisible: false,
              }}
            />
          </Stack.Group>

          <Stack.Group screenOptions={{ presentation: 'modal', gestureEnabled: true }}>
            <Stack.Screen component={withNativeBase(DevToolsScreen)} name="DevTools" />
          </Stack.Group>
        </Stack.Navigator>

        {children}
      </NavigationContainer>
    </Suspense>
  );
}

type RootStackScreenProps<Screen extends keyof RootStackParamList> = NativeStackScreenProps<
  RootStackParamList,
  Screen
>;

export type RootNavigationProp = RootStackScreenProps<'Destinations'>['navigation'];

// IMPORTANT: using this approach to make navigation.navigate() typesafe from root
export type RootScreenProps<
  T extends Record<string, Record<string, unknown> | undefined>,
  S extends keyof T
> = {
  navigation: RootNavigationProp;
  route: RouteProp<T, S>;
};

declare global {
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RootParamList extends RootStackParamList {}
  }
}
