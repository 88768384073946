import { Box, Button, HStack, Pressable, Text } from 'native-base';
import React, { useRef, useState } from 'react';

import CPAlertDialog from 'app/components/CPAlertDialog';
import { ProfileIcon } from 'app/components/icons';
import useAuth from 'app/hooks/useAuth';
import useRootNavigation from 'app/hooks/useRootNavigation';
import { useI18n } from 'app/providers/I18nProvider';
import { tID } from 'app/services/TestHelper';

export default function AccountHeader() {
  const { t } = useI18n();
  const { isVerified, email, signOut } = useAuth();
  const signOutRef = useRef();
  const navigation = useRootNavigation();
  const [isOpen, setIsOpen] = useState(false);

  const handleGoToAuth = () => {
    navigation.navigate('Authentication');
  };

  const handleSignOut = () => {
    signOut();
    setIsOpen(false);
    handleGoToAuth();
  };

  const handleCancelSignOut = async () => {
    setIsOpen(false);
  };

  return (
    <Box paddingY={4} testID={tID('AccountHeader')}>
      <HStack alignItems="center" flexDirection="row" justifyContent="space-between">
        <Pressable
          testID={tID('AccountHeaderAuthButton')}
          onPress={() => {
            if (!isVerified) {
              handleGoToAuth();
            }
          }}
        >
          <HStack alignItems="center" flexDirection="row">
            <Box backgroundColor="brand.secondary" borderRadius="full" padding={2}>
              <ProfileIcon color="white" />
            </Box>

            <Text
              color="brand.secondary"
              fontWeight="bold"
              marginLeft={3}
              textDecorationLine={isVerified ? 'none' : 'underline'}
            >
              {isVerified ? email : t('app_auth_sign_in')}
            </Text>
          </HStack>
        </Pressable>
        {isVerified && (
          <Button
            _text={{ fontWeight: 'normal' }}
            color="brand.secondary"
            marginRight={-4}
            variant="link"
            onPress={() => setIsOpen(true)}
          >
            {t('app_auth_sign_out')}
          </Button>
        )}
      </HStack>

      <CPAlertDialog isOpen={isOpen} leastDestructiveRef={signOutRef}>
        <CPAlertDialog.Content testID={tID('SignOutDialog')}>
          <CPAlertDialog.Header>{`${t('app_auth_sign_out')}?`}</CPAlertDialog.Header>
          <CPAlertDialog.Body>{t('app_auth_sign_out_description')}</CPAlertDialog.Body>
          <CPAlertDialog.Footer>
            <Button onPress={handleSignOut}>{t('gen_yes')}</Button>
            <Button variant="link" onPress={handleCancelSignOut}>
              {t('gen_no')}
            </Button>
          </CPAlertDialog.Footer>
        </CPAlertDialog.Content>
      </CPAlertDialog>
    </Box>
  );
}
