import { Flex, Text } from 'native-base';
import React, { ReactNode } from 'react';
import { shallow } from 'zustand/shallow';

import DevToolsTitle from 'app/components/DevTools/DevToolsTitle/DevToolsTitle';
import useAuth from 'app/hooks/useAuth';
import useDebugInfo from 'app/hooks/useDebugInfo';
import useStore from 'app/hooks/useStore';
import { useI18n } from 'app/providers/I18nProvider';

export type DebugInfoProps = {
  children?: ReactNode;
};

export default function DebugInfo() {
  const { locale } = useI18n();
  const [
    email,
    authMode,
    emailReservingFor,
    verificationState,
    entitlementKey,
    orderAuthToken,
    productId,
  ] = useStore(
    (s) => [
      s.email,
      s.authMode,
      s.emailReservingFor,
      s.verificationState,
      s.entitlementKey,
      s.orderAuthToken,
      s.productId,
    ],
    shallow
  );
  const info = useDebugInfo();
  const { user } = useAuth();

  return (
    <Flex justifyContent="center" testID="DebugInfo">
      <DevToolsTitle>App</DevToolsTitle>
      <InfoRowPartial left="App Version" right={info.appVersion} />
      <InfoRowPartial left="Expo SDK Version" right={info.sdkVersion} />
      <InfoRowPartial left="Expo Version" right={info.expoVersion} />
      <InfoRowPartial left="Profile" right={info.profile || 'N/A'} />
      <InfoRowPartial left="Build ID" right={info.buildid || 'N/A'} />
      <InfoRowPartial left="Mode" right={info.mode} />
      <InfoRowPartial left="Platform" right={info.platform} />
      <InfoRowPartial left="Commit Hash" right={info.commit || 'N/A'} />

      <DevToolsTitle mt="4">Device</DevToolsTitle>
      <InfoRowPartial left="Device OS" right={info.deviceOS} />
      <InfoRowPartial left="Device Type" right={info.deviceType} />
      <InfoRowPartial left="Device Language" right={info.deviceLanguage} />
      <InfoRowPartial left="Session ID" right={info.sessionId} />

      <DevToolsTitle mt="4">User</DevToolsTitle>
      <InfoRowPartial left="User Language" right={locale} />
      <InfoRowPartial left="Verification State" right={verificationState} />
      <InfoRowPartial
        left="User Verified"
        right={user ? JSON.stringify(user.emailVerified) : 'false'}
      />
      <InfoRowPartial left="Auth Model" right={authMode} />
      <InfoRowPartial left="User Email" right={user ? user.email : ''} />
      <InfoRowPartial left="User ID" right={user ? user.uid : ''} />
      <InfoRowPartial left="User Name" right={user ? user.displayName : ''} />
      <InfoRowPartial left="Reserving For Email" right={email} />
      <InfoRowPartial left="Email Reserving For" right={emailReservingFor} />
      <InfoRowPartial left="Product Key" right={productId} />
      <InfoRowPartial left="Entitlement Key" right={entitlementKey} />
      <InfoRowPartial left="Order Auth Token" right={orderAuthToken} />
      <InfoRowPartial left="Heap User ID" noBorder right={info.heapUserId} />

      <DevToolsTitle mt="4">Data</DevToolsTitle>
      <InfoRowPartial left="GuestCenter API" right={info.guestCenterTarget} />
      <InfoRowPartial left="Hygraph API" right={info.hygraphApiURL} />
    </Flex>
  );
}

export function InfoRowPartial({
  left,
  leftColumnPercent = 25,
  right,
  noBorder,
}: {
  left: ReactNode;
  right: ReactNode;
  noBorder?: boolean;
  leftColumnPercent?: number;
}) {
  return (
    <Flex
      borderBottomWidth={noBorder ? 0 : 1}
      borderColor="brand.border"
      flexDirection="row"
      maxW="full"
      mb={1}
      pb={1}
      width={{
        base: 'md',
        md: 'lg',
        lg: 'xl',
      }}
    >
      <Text
        bold
        flexBasis={`${leftColumnPercent.toString()}%`}
        flexWrap="wrap"
        pr="1"
        textAlign="left"
      >
        {left}
      </Text>
      <Text
        flexBasis={`${(100 - leftColumnPercent).toString()}%`}
        flexWrap="wrap"
        overflow="hidden"
        pl="1"
      >
        {right}
      </Text>
    </Flex>
  );
}
