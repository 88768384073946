import Constants from 'expo-constants';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { toMs } from 'app/utils/time';

type Feature = keyof typeof FEATURES;
type FeatureFlags = Record<Feature, boolean>;

const defaults = Constants.expoConfig?.extra?.features || {};

export const FEATURES_QUERY_KEY = 'features';
export const FEATURES = {
  destHeaderBlue: defaults.destHeaderBlue === true ?? false,
  reservationDevTools: defaults.destHeaderBlue === true ?? false,
};

export default function useFeatures() {
  const { data: features } = useQuery<FeatureFlags>(FEATURES_QUERY_KEY, () => FEATURES, {
    ...(process.env.ENVIRONMENT === 'production'
      ? {
          refetchInterval: toMs(1, 'h'),
          staleTime: toMs(1, 'h'),
        }
      : { staleTime: Infinity }),
  });

  // so we dont have to guard undefined
  return features ?? FEATURES;
}

export const useToggleFeature = () => {
  const queryClient = useQueryClient();
  const features = useFeatures();
  const toggleFeature = useCallback(
    (feature: Feature) => {
      queryClient.setQueryData('features', {
        ...features,
        [feature]: !features[feature],
      });
    },
    [queryClient, features]
  );

  return toggleFeature;
};
