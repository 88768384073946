import { Box, Divider, HStack, Pressable, Text, useDisclose, VStack } from 'native-base';
import React from 'react';
import { Linking } from 'react-native';

import AccountHeader from 'app/components/AccountHeader';
import AllDestinations from 'app/components/icons/menus/AllDestinations';
import AppInfo from 'app/components/icons/menus/AppInfo';
import CityPassDotCom from 'app/components/icons/menus/CityPassDotCom';
import ExternalLink from 'app/components/icons/menus/ExternalLink';
import HelpCenter from 'app/components/icons/menus/HelpCenter';
import Language from 'app/components/icons/menus/Language';
import MyPurchases from 'app/components/icons/menus/MyPurchases';
import Notifications from 'app/components/icons/menus/Notifications';
import PrivacyPolicy from 'app/components/icons/menus/PrivacyPolicy';
import ReturnPolicy from 'app/components/icons/menus/ReturnPolicy';
import TermsAndConditions from 'app/components/icons/menus/TermsAndConditions';
import LinkTicketsPrompt from 'app/components/LinkTicketsPrompt';
import RedirectDialog from 'app/components/RedirectDialog';
import Screen from 'app/components/Screen';
import useStore from 'app/hooks/useStore';
import { ProductTabParamList } from 'app/navigation/AttractionTicketsStackNavigator';
import { RootScreenProps } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';
import Analytics, { Events } from 'app/services/Analytics';
import { webHome, webReturnPolicy, webSupport } from 'app/services/LinkHelper';
import { tID } from 'app/services/TestHelper';

export type MenuScreenProps = RootScreenProps<ProductTabParamList, 'Menu'>;

export default function MenuScreen({ navigation }: MenuScreenProps) {
  const { locale, t } = useI18n();
  const productId = useStore((s) => s.productId);
  const supportUrl = webSupport({ locale });
  const citypassWebUrl = webHome({ locale });
  const returnPolicyUrl = webReturnPolicy({ locale });

  return (
    <Screen
      after={<LinkTicketsPrompt productContentKey={productId ?? ''} />}
      testID={tID('MenuScreen')}
    >
      <VStack space="4">
        <AccountHeader />

        <Box>
          <MenuGroupTitlePartial label={t('app_menu_support')} />

          <MenuItemPartial
            analyticsID="HelpCenter"
            external
            label={t('app_menu_help_center')}
            menuIcon={<HelpCenter />}
            testID={tID('menu-help-center')}
            url={supportUrl}
            onPress={() => {
              Linking.openURL(supportUrl);
            }}
          />

          {/* <MenuItemPartial
            analyticsID="Chat"
            label={t('app_menu_live_chat')}
            menuIcon={<LiveChat />}
            testID={tID('menu-live-chat')}
            onPress={() => {
              navigation.navigate('Chat');
            }}
          />

          <MenuItemPartial
            label={t('app_menu_report_an_issue')}
            menuIcon={<ReportAnIssue />}
            testID={tID('menu-feedback')}
            analyticsID="Feedback"
            onPress={() => {
              navigation.navigate('Feedback');
            }}
          /> */}
        </Box>

        <Divider />

        <Box>
          <MenuGroupTitlePartial label={t('app_menu_my_info')} />

          <MenuItemPartial
            analyticsID="Purchases"
            label={t('app_menu_my_purchases')}
            menuIcon={<MyPurchases />}
            testID={tID('menu-purchases')}
            onPress={() => {
              navigation.navigate('Purchases');
            }}
          />

          <MenuItemPartial
            analyticsID="Notifications"
            label={t('app_menu_notifications')}
            menuIcon={<Notifications />}
            testID={tID('menu-notifications')}
            onPress={() => {
              navigation.navigate('Notifications');
            }}
          />

          <MenuItemPartial
            analyticsID="Language"
            label={t('app_menu_language')}
            menuIcon={<Language />}
            testID={tID('menu-language')}
            onPress={() => {
              navigation.navigate('Language');
            }}
          />
        </Box>

        <Divider />

        <Box>
          <MenuGroupTitlePartial label={t('app_menu_general')} />

          <MenuItemPartial
            analyticsID="Destinations"
            label={t('app_menu_all_destinations')}
            menuIcon={<AllDestinations />}
            testID={tID('menu-destinations')}
            onPress={() => {
              navigation.navigate('Destinations');
            }}
          />

          <MenuItemPartial
            analyticsID="CitypassDotCom"
            external
            label={t('app_menu_citypass_dot_com')}
            menuIcon={<CityPassDotCom />}
            testID={tID('menu-citypass')}
            url={citypassWebUrl}
            onPress={() => {
              Linking.openURL(citypassWebUrl);
            }}
          />

          <MenuItemPartial
            analyticsID="TermsConditions"
            label={t('app_menu_terms_and_conditions')}
            menuIcon={<TermsAndConditions />}
            testID={tID('menu-terms')}
            onPress={() => {
              navigation.navigate('TermsConditions');
            }}
          />

          <MenuItemPartial
            analyticsID="ReturnPolicy"
            external
            label={t('app_menu_return_policy')}
            menuIcon={<ReturnPolicy />}
            testID={tID('menu-return')}
            url={returnPolicyUrl}
            onPress={() => {
              Linking.openURL(returnPolicyUrl);
            }}
          />
          <MenuItemPartial
            analyticsID="PrivacyPolicy"
            label={t('app_menu_privacy_policy')}
            menuIcon={<PrivacyPolicy />}
            testID={tID('menu-privacy')}
            onPress={() => {
              navigation.navigate('PrivacyPolicy');
            }}
          />

          <MenuItemPartial
            analyticsID="AppInfo"
            label={t('app_menu_app_info')}
            menuIcon={<AppInfo />}
            testID={tID('menu-app-info')}
            onPress={() => {
              navigation.navigate('AppInfo');
            }}
          />
        </Box>
      </VStack>
    </Screen>
  );
}

function MenuGroupTitlePartial({ label }: { label: string }) {
  return (
    <Box mb="3">
      <Text color="text.600" fontSize="sm" textTransform="uppercase">
        {label}
      </Text>
    </Box>
  );
}

function MenuItemPartial({
  label,
  external = false,
  url = '',
  disabled,
  onPress,
  testID,
  menuIcon,
  analyticsID,
}: {
  label: string;
  analyticsID: string;
  external?: boolean;
  url?: string;
  disabled?: boolean;
  testID?: string;
  menuIcon: JSX.Element;
  onPress: () => void;
}) {
  const { isOpen, onClose, onOpen } = useDisclose();

  const handlePressed = async () => {
    if (external) onOpen();
    else onPress();
  };

  return (
    <Box>
      <Pressable
        _pressed={disabled ? undefined : { bg: 'muted.100' }}
        mx="-2.5"
        px="2.5"
        py="3"
        rounded="md"
        testID={testID}
        onPress={
          disabled
            ? undefined
            : () => {
                Analytics.trackEvent((Events.NavMore + analyticsID) as Events);
                handlePressed();
              }
        }
      >
        <HStack alignItems="flex-start" space="2">
          {menuIcon}
          <Text color={disabled ? 'muted.400' : 'black'}>{label}</Text>
          {external ? (
            <Box mt="0.5">
              <ExternalLink />
            </Box>
          ) : null}
        </HStack>
      </Pressable>

      <RedirectDialog isOpen={isOpen} url={url} onClose={onClose} onConfirm={onPress} />
    </Box>
  );
}
