import { Box } from 'native-base';
import React, { ReactNode } from 'react';

import ContentBlock, { ContentBlockProps } from 'app/components/ContentBlock/ContentBlock';
import { CMSBlock, getComponentName } from 'app/components/ContentBlock/helpers';
import PartnerOffers from 'app/components/PartnerOffers';
import { useProductContext } from 'app/providers/ProductProvider';
import ProductBroadwayPromo from 'app/screens/ProductScreen/ProductBroadwayPromo';
import ProductFAQ from 'app/screens/ProductScreen/ProductFAQ';
import ProductOffers from 'app/screens/ProductScreen/ProductOffers';
import ProductReview from 'app/screens/ProductScreen/ProductReview';

const blockComponents: Record<string, (props: ContentBlockProps) => JSX.Element | null> = {
  PartnerOffers: (props) => (
    <PartnerOffers empty={<Box mt={-props.space} />} placement="product" {...props} />
  ),
  ProductBroadwayPromo,
  ProductReview,
  ProductOffers,
  ProductFAQ,
};

export type ContentBlockRendererProps = CMSBlock & {
  children?: ReactNode;
  testID?: string;
  space: number;
};

export default function ContentBlockRenderer(props: ContentBlockRendererProps) {
  const { productContent } = useProductContext();
  const Component = blockComponents[getComponentName(props) ?? ''] ?? ContentBlock;

  return <Component {...props} productContent={productContent} testID={props.testID} />;
}
