import noop from 'lodash/noop';
import pick from 'lodash/pick';
import { createContext, ReactNode, useEffect, useMemo } from 'react';
import { AppState, AppStateStatus, Platform } from 'react-native';
import { useMutation } from 'react-query';
import { shallow } from 'zustand/shallow';

import useGuestDataQuery from 'app/hooks/useGuestDataQuery';
import { handleOrderAuthToken } from 'app/hooks/useLinkHandling';
import useStore, { AuthMode, VerificationState } from 'app/hooks/useStore';
import { AuthContextProps } from 'app/providers/AuthProvider/AuthProvider.types';
import Analytics from 'app/services/Analytics';
import { auth } from 'app/services/Firebase';
import { getAuthCodeValidition, signInWithEmail } from 'app/services/GuestCenterService';
import Logger from 'app/services/Logger';
import { QueryKeys } from 'app/services/QueryClient';
import { isObject } from 'app/utils/guards';

const MODULE = '[AuthProvider]';

export const AuthContext = createContext<AuthContextProps>({
  email: null,
  user: null,
  getToken: () => null,
  signOut: () => Promise.resolve(),
  isVerified: false,
  isProcessing: false,
  isPending: false,
  isUnverified: false,
  verifyEmail: {
    reset: noop,
    mutate: noop,
    mutateAsync: noop,
  } as AuthContextProps['verifyEmail'],
  verificationState: VerificationState.Unverified,
  verificationProcessing: false,
  verificationMessage: null,
  setVerificationMessage: noop,
  resetVerificationState: noop,
  authCode: '',
  setAuthCode: noop,
  verifyCode: {
    reset: noop,
    mutate: noop,
    mutateAsync: noop,
  } as AuthContextProps['verifyCode'],
});

export type AuthProviderProps = {
  children: ReactNode;
};

export default function AuthProvider({ children }: AuthProviderProps) {
  const { remove: clearGuestData, refetch: refetchGuestData } = useGuestDataQuery({
    notifyOnChangeProps: [],
  });
  const {
    email: $email,
    locale,
    user,
    accessToken,
    authMode,
    setVerificationState,
    verificationState,
    verificationProcessing,
    verificationMessage,
    reset,
    resetVerificationState,
    setVerificationMessage,
    emailVerificationPending,
    authCode,
    setAuthCode,
  } = useStore(
    (s) =>
      pick(s, [
        'locale',
        'email',
        'user',
        'authMode',
        'accessToken',
        'setVerificationState',
        'setEmail',
        'verificationState',
        'verificationProcessing',
        'verificationMessage',
        'reset',
        'setVerificationMessage',
        'resetVerificationState',
        'emailVerificationPending',
        'authCode',
        'setAuthCode',
      ]),
    shallow
  );

  const verifyEmail = useMutation(
    QueryKeys.VerifyEmail,
    async ({ email }: { email: string }) => {
      const res = await signInWithEmail(email, locale);
      if (res.data?.success !== true) throw res;
      return res.data;
    },
    {
      onMutate: ({ email }) => {
        Logger.debug(`${MODULE} verify email sign in starting`, {
          email,
          uid: auth().currentUser?.uid,
        });
      },
      onSettled: () => {
        Logger.debug(`${MODULE} verify email sign in settled`, {});
      },
      onSuccess: (data, { email }) => {
        Logger.debug(`${MODULE} verify email sign in success`, {
          email,
          data,
        });
        emailVerificationPending({ email, authCodeToken: data.codeToken });
      },
      onError: (err, { email }) => {
        Logger.error(`${MODULE} verify email sign in error`, { email, err });
      },
    }
  );

  const verifyCode = useMutation(
    QueryKeys.VerifyCode,
    async (props: { email: string; authCode: string }) => {
      return verifyAuthCode({ ...props, refetchGuestData, clearGuestData });
    },
    {
      onMutate: (props) => {
        Logger.debug(`${MODULE} verify auth code starting`, props);
      },
      onSettled: () => {
        Logger.debug(`${MODULE} verify auth code settled`);
      },
      onSuccess: (data, props) => {
        Logger.debug(`${MODULE} verify auth code success`, { data, props });
      },
      onError: (error, props) => {
        Logger.error(`${MODULE} verify auth code error`, {
          props,
          error,
          cause: isObject(error) && 'cause' in error ? error?.cause : undefined,
        });
      },
    }
  );

  const context = useMemo(
    () => ({
      user,
      email: $email,
      isVerified: verificationState === VerificationState.Verified,
      isPending: verificationState === VerificationState.Pending,
      isUnverified: verificationState === VerificationState.Unverified,
      isProcessing: verificationProcessing,
      verificationState,
      verificationProcessing,
      verificationMessage,
      getToken: () => user?.getIdToken() ?? accessToken,
      signOut: () => {
        auth().signOut();
        clearGuestData();
        Logger.debug('[AuthProvider] signOut clear guest data');
        if (reset) reset();
      },
      resetVerificationState,
      setVerificationMessage,
      verifyEmail,
      authCode,
      setAuthCode,
      verifyCode,
    }),
    [
      user,
      $email,
      verificationState,
      verificationProcessing,
      verificationMessage,
      authCode,
      setAuthCode,
      verifyCode,
      resetVerificationState,
      setVerificationMessage,
      verifyEmail,
      accessToken,
      clearGuestData,
      reset,
    ]
  );

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged((currentUser) => {
      const { user: $user, setUser, setAccessToken } = useStore.getState();

      if (currentUser) {
        Logger.debug('[useAuth] signed in', { user: currentUser });
        if (currentUser.uid) Analytics.setUserId(currentUser.uid);
        if ($user?.uid !== currentUser?.uid || $user.emailVerified !== currentUser.emailVerified) {
          setUser(currentUser);
          currentUser.getIdToken().then((token) => {
            setAccessToken(token);
          });
        }
      } else {
        Logger.debug('[useAuth] signed out', { user: currentUser });
        if ($user) {
          resetVerificationState();
        }
      }
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web') {
      const handleChange = (nextState: AppStateStatus) => {
        if (nextState === 'active') {
          auth()
            .currentUser?.reload()
            .then(() => {
              if (auth().currentUser?.emailVerified) {
                setVerificationState(VerificationState.Verified);
              }
            });
        }
      };

      const subscription = AppState.addEventListener('change', handleChange);
      return () => {
        subscription.remove();
      };
    }
    return noop;
  }, [authMode, setVerificationState]);

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
}

export const AUTH_CODE_LENGTH = 6;

export function isValidAuthCode(code: string) {
  return new RegExp(`^\\d{${AUTH_CODE_LENGTH}}$`).test(code);
}

async function verifyAuthCode({
  email,
  authCode,
  refetchGuestData,
  clearGuestData,
}: {
  email: string;
  authCode: string;
  refetchGuestData: () => void;
  clearGuestData: () => void;
}) {
  const { authMode, orderAuthToken, authCodeToken, setVerificationProcessing, setUserVerified } =
    useStore.getState();

  setVerificationProcessing(true);

  try {
    const { data } = await getAuthCodeValidition({
      email,
      code: authCode,
      codeToken: authCodeToken ?? '',
    });

    if (data.success) {
      await auth().signInWithCustomToken(data.token);
      setUserVerified();

      // Token Flow: close the loop on updating the email address for tokens representing a travel party that had no email set
      if (authMode === AuthMode.TokenVerifyEmail && orderAuthToken) {
        await handleOrderAuthToken(orderAuthToken, email, clearGuestData);
      }

      refetchGuestData();
      return data;
    }
    throw data;
  } catch (err) {
    throw new Error('failed to verify auth code', {
      cause: err,
    });
  } finally {
    setVerificationProcessing(false);
  }
}
