import { Box, VStack } from 'native-base';
import React from 'react';

import Hero from 'app/components/Hero';
import HTML from 'app/components/HTML';
import Screen from 'app/components/Screen';
import useProduct from 'app/hooks/useProduct';
import { ProductStackParamList } from 'app/navigation/ProductStackNavigator';
import { RootScreenProps } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';
import { getFAQs } from 'app/services/GeneralHelper';
import { getHero } from 'app/services/ProductHelper';

export type ProductFAQScreenProps = RootScreenProps<ProductStackParamList, 'ProductFAQ'>;

export default function ProductFAQScreen({ navigation, route }: ProductFAQScreenProps) {
  const { locale } = useI18n();
  const { productId: productContentKey } = route.params ?? {};
  const { productContent } = useProduct({ productContentKey });

  const faqs = getFAQs({
    faqs: productContent?.faqs,
    product: productContent,
    locale,
  });

  return (
    <Screen beforeContainer={<Hero url={getHero(productContent).url} />} testID="ProductFAQScreen">
      <VStack space="3">
        {faqs.map(({ question, answer }) => (
          <Box key={question}>
            <HTML html={`<strong>${question}</strong> ${answer}`} />
          </Box>
        ))}
      </VStack>
    </Screen>
  );
}
