import { Box, Input, Text, Toast, useToken, VStack } from 'native-base';
import React, { useEffect } from 'react';
import { Platform, Pressable } from 'react-native';
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';

import AuthTitle from 'app/components/AuthTitle';
import HTML from 'app/components/HTML';
import EmailIndicator from 'app/components/icons/EmailIndicator';
import Screen from 'app/components/Screen';
import TextLink from 'app/components/TextLink';
import useToggle from 'app/hooks/useToggle';
import { AuthenticationStackParamList } from 'app/navigation/AuthenticationStackNavigator';
import { useAuthenticationNav } from 'app/navigation/AuthenticationStackNavigator/AuthenticationNavProvider';
import { RootScreenProps } from 'app/navigation/RootNavigator';
import { AUTH_CODE_LENGTH, isValidAuthCode } from 'app/providers/AuthProvider';
import { useI18n } from 'app/providers/I18nProvider';
import LanguageSelect from 'app/screens/Authentication/LanguageSelect';
import { tID } from 'app/services/TestHelper';

const MODULE = '[AuthentictionVerifyEmailScreen]';

export type AuthenticationStepVerifyEmailScreenProps = RootScreenProps<
  AuthenticationStackParamList,
  'AuthenticationStepVerifyEmail'
>;

export default function AuthenticationVerifyEmailScreen({
  navigation,
  route,
}: AuthenticationStepVerifyEmailScreenProps) {
  const { t } = useI18n();
  const {
    email,
    handleResendVericationEmail,
    handleManualSignInLink,
    authCode,
    setAuthCode,
    verifyCode,
  } = useAuthenticationNav();
  const errorColor = useToken('colors', 'brand.error');
  const [showSignInLinkInput, toggleSignInLinkInput] = useToggle(false);
  const ref = useBlurOnFulfill({ value: authCode, cellCount: 7 });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value: authCode,
    setValue: setAuthCode,
  });

  const handleChangeText = (value: string) => {
    setAuthCode(value.replace(/\D/g, ''));
  };

  useEffect(() => {
    if (authCode.length < AUTH_CODE_LENGTH && verifyCode.isError) {
      verifyCode.reset();
    }
    if (!verifyCode.isError && !verifyCode.isLoading && email && isValidAuthCode(authCode)) {
      verifyCode.mutate(
        {
          email,
          authCode,
        },
        {
          onError() {
            const id = 'invalid_code';
            if (!Toast.isActive(id)) {
              Toast.show({
                id,
                title: t('app_error_invalid_code_title'),
                description: t('app_error_invalid_code_body'),
                style: {
                  backgroundColor: errorColor,
                },
              });
            }
          },
        }
      );
    }
  }, [authCode, verifyCode, email, errorColor, t]);

  return (
    <Screen after={<LanguageSelect />} flexGrow="1" testID="AuthenticationVerifyEmailScreen">
      <VStack space="6">
        <Box alignItems="center" justifyContent="center" mt="8">
          <EmailIndicator size={58} />
        </Box>
        <AuthTitle>{t('app_auth_verify_code_title')}</AuthTitle>

        <Box testID={tID('text-email-sent-details')}>
          <Pressable testID={tID('press-show-link-input')} onLongPress={toggleSignInLinkInput}>
            <HTML
              html={t<string>('app_auth_verify_code_intro', { email: email ?? '' })}
              textAlign="center"
            />
          </Pressable>
        </Box>

        <CodeField
          ref={ref}
          {...props}
          caretHidden
          cellCount={AUTH_CODE_LENGTH}
          keyboardType="number-pad"
          renderCell={({ index, symbol, isFocused }) => (
            <Box
              key={index}
              bg="gray.100"
              borderColor={getBorderColor({ isFocused, isError: verifyCode.isError })}
              borderWidth="2"
              rounded="md"
              textAlign="center"
              w="10"
              onLayout={getCellOnLayoutHandler(index)}
            >
              <Text
                key={index}
                color={getTextColor({
                  isFocused: isFocused || !!symbol,
                  isError: verifyCode.isError,
                })}
                fontSize="2xl"
                py="2"
                textAlign="center"
                w="full"
              >
                {symbol || (isFocused ? <Cursor /> : '0')}
              </Text>
            </Box>
          )}
          rootStyle={{
            maxWidth: '100%',
            justifyContent: 'space-evenly',
          }}
          textContentType="oneTimeCode"
          value={authCode}
          onChangeText={handleChangeText}
        />

        <Text mt="4" textAlign="center">
          {t('app_auth_verify_code_help')}{' '}
          <TextLink onPress={handleResendVericationEmail}>
            {t('app_auth_verify_code_resend')}
          </TextLink>
        </Text>

        {Platform.OS !== 'web' && showSignInLinkInput && (
          <Input
            _focus={{
              borderColor: 'black',
            }}
            clearButtonMode="while-editing"
            keyboardType="url"
            placeholder="Paste Sign In Link Here"
            px="0"
            size="lg"
            testID={tID('input-paste-sign-in-link')}
            textContentType="URL"
            type="text"
            variant="underlined"
            onChangeText={handleManualSignInLink}
          />
        )}
      </VStack>
    </Screen>
  );
}

function getBorderColor({ isFocused, isError }: { isFocused: boolean; isError: boolean }) {
  if (isError) return 'error.500';
  return isFocused ? 'black' : 'gray.100';
}

function getTextColor({ isFocused, isError }: { isFocused: boolean; isError: boolean }) {
  if (isError) return 'error.500';
  return isFocused ? 'black' : 'gray.300';
}
