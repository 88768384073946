import sortBy from 'lodash/sortBy';

import { GetDestinationsQuery } from 'app/generated/hygraph';
import { getReservationTypeByAttractionKey } from 'app/services/AttractionHelper';
import {
  AttractionConfig,
  AttractionGroup,
  Entitlement,
  EntitlementReservation,
  Pass,
  ProductFamily,
  ProductLineup,
  Reservation,
  ReservationRequirement,
  TicketClass,
  TravelParty,
  ValidStates,
} from 'app/services/GuestCenterService.types';
import Logger from 'app/services/Logger';
import { getAttractionTickets } from 'app/services/PassHelper';
import { createAppProductLineup } from 'app/services/ProductLineupHelper';
import { getOptions } from 'app/services/ReservationHelper';

function getReservationRequirement(
  productLineup: ProductLineup,
  attractionConfigs: AttractionConfig[]
): ReservationRequirement {
  const configMap = new Map(attractionConfigs.map((conf) => [conf.attractionKey, conf]));

  return productLineup.attractionGroups.reduce<ReservationRequirement>((requirement, group) => {
    if (requirement === ReservationRequirement.Required) return requirement;

    // eslint-disable-next-line no-restricted-syntax
    for (const attraction of group.attractions) {
      const key = attraction.attractionKey;
      const config = configMap.get(key);

      if (config?.reservationRequirement === ReservationRequirement.Required)
        return ReservationRequirement.Required;
      if (config?.reservationRequirement === ReservationRequirement.Recommended)
        return ReservationRequirement.Recommended;
    }

    return requirement;
  }, ReservationRequirement.NotRequired);
}

export function createEntitlement({
  entitlementKey: maybeEntitlementKey,
  travelParty,
  passes,
  reservations,
  productLineup,
  attractionConfigs,
  destinationsContent,
}: {
  entitlementKey: Maybe<string>;
  travelParty: Maybe<TravelParty>;
  passes: Pass[];
  reservations: Reservation[];
  productLineup: ProductLineup;
  attractionConfigs: AttractionConfig[];
  destinationsContent: GetDestinationsQuery['products'];
}) {
  // won't need this if we  move away from locators
  const entitlementKey = maybeEntitlementKey ?? 'no-entitlement-key';
  const productContentKey = productLineup?.contentKey ?? '';
  const entPasses = sortBy(
    passes.filter((pass) => pass.locator === entitlementKey),
    ['ticketClass', 'name']
  );
  const entReservations: EntitlementReservation[] = reservations
    .filter(({ attractionKey }) => {
      return productLineup?.attractionGroups.some((group) =>
        group.attractions.some((a) => a.attractionKey === attractionKey)
      );
    })
    .map((reservation) => {
      return createEntitlementReservation(reservation, {
        entitlementKey,
        passes,
        productLineup,
        attractionConfigs,
      });
    })
    .filter((reservation) => {
      return reservation.passes.length > 0;
    });
  const quantity = getPassQuantities(entPasses);
  const name = destinationsContent.find((product) => product.key === productContentKey)?.name ?? '';

  return {
    entitlementKey,
    travelParty,
    name,
    productContentKey,
    productLineup: createAppProductLineup({
      productLineup,
      productContentLineups: null,
      entitlement: {
        passes: entPasses,
        reservations: entReservations,
      },
    }),
    passes: entPasses,
    reservations: entReservations,
    quantity,
    isValid: travelParty?.status === ValidStates.Valid ?? true,
    reservationRequirement: getReservationRequirement(productLineup, attractionConfigs),
  };
}

export function getFamilyCode(entitlement: Maybe<Entitlement>) {
  return entitlement?.productLineup.familyCode;
}

export function getProductCode(entitlement: Maybe<Entitlement>) {
  return getProductCodeFromPasses(entitlement?.passes);
}

export function getProductCodeFromPasses(passes: Maybe<Pass[]>) {
  return passes?.[0]?.productCode;
}

export function getReservationByKey(entitlement: Maybe<Entitlement>, reservationKey: string) {
  return entitlement?.reservations.find((res) => res.reservationKey === reservationKey);
}

export function getReservationByAttractionKey(
  entitlement: Maybe<Entitlement>,
  attractionKey: string
) {
  return entitlement?.reservations.find((res) => res.attractionKey === attractionKey);
}

export function hasReservationByAttractionKey(
  entitlement: Maybe<Entitlement>,
  attractionKey: string
) {
  return !!getReservationByAttractionKey(entitlement, attractionKey);
}

export function getReservationsByAttractionKey(
  entitlement: Maybe<Entitlement>,
  attractionKey: string
) {
  return entitlement?.reservations.filter((res) => res.attractionKey === attractionKey) ?? [];
}

export function getReservationByAttractionContentKey(
  entitlement: Maybe<Entitlement>,
  attractionContentKey: string
) {
  return entitlement?.reservations.find(
    (res) => res.attractionConfig.contentKey === attractionContentKey
  );
}

export function hasReservationsForAllPassesByAttractionKey(
  entitlement: Maybe<Entitlement>,
  attractionKey: string
) {
  if (!entitlement || entitlement.reservations.length === 0 || entitlement.passes.length === 0)
    return false;

  const reservations = getReservationsByAttractionKey(entitlement, attractionKey);
  const reservationBarcodes = reservations.reduce<{ [barcode: string]: boolean }>((acc, res) => {
    res.barcodes.forEach((barcode) => {
      acc[barcode] = true;
    });
    return acc;
  }, {});

  return entitlement.passes.every((pass) => reservationBarcodes[pass.barcode]);
}

export function getRemainingVisits(entitlement: Maybe<Entitlement>) {
  if (!entitlement) return 0;
  return entitlement.productLineup.remainingVisits;
}

export function hasRemainingVisits(entitlement: Maybe<Entitlement>) {
  return getRemainingVisits(entitlement) > 0;
}

function isAlacarte(entitlement: Maybe<Entitlement>) {
  return getFamilyCode(entitlement) === ProductFamily.Alacarte;
}

export function canVisitAttraction(entitlement: Maybe<Entitlement>, attractionKey: string) {
  // no entitlement, alacarte, or reservation exists for attraction
  if (
    !entitlement ||
    isAlacarte(entitlement) ||
    !!getReservationByAttractionKey(entitlement, attractionKey)
  ) {
    return true;
  }

  const group = getAttractionGroupByAttractionKey(entitlement, attractionKey);
  if (!group || group.remainingVisits === 0) {
    Logger.debug('[EntitlementHelper] no remaining visits for group or no group found', group);
    return false;
  }

  return entitlement.productLineup.remainingVisits > 0;
}

export function getAttractionGroupByAttractionKey(
  entitlement: Maybe<Entitlement>,
  attractionKey: string
) {
  return entitlement?.productLineup.attractionGroups.find((group) => {
    return group.attractions.some((a) => a.attractionKey === attractionKey);
  });
}

export function getGroupEntitlement(group: Maybe<AttractionGroup>, productCode: Maybe<string>) {
  return group?.entitlements.find((e) => e.productCode === productCode);
}

export function getPassCount(entitlement: Maybe<Entitlement>) {
  return entitlement?.passes.length ?? 0;
}

export function getPassCountByTicketClass(
  entitlement: Maybe<Entitlement>,
  ticketClass: TicketClass
) {
  return entitlement?.passes.reduce(
    (acc, pass) => acc + (pass.ticketClass === ticketClass ? 1 : 0),
    0
  );
}

export function hasReservations(entitlement: Maybe<Entitlement>) {
  if (!entitlement?.reservations) return false;
  return entitlement.reservations.length > 0;
}

export function hasPasses(entitlement: Maybe<Entitlement>) {
  return getPassCount(entitlement) > 0;
}

export function getPassCountByClass(passes: Pass[], ticketClass: TicketClass) {
  return passes.reduce((acc, pass) => (pass.ticketClass === ticketClass ? acc + 1 : acc), 0);
}

export function hasAttractionTickets(entitlement: Maybe<Entitlement>, attractionKey: string) {
  return getAttractionTickets(entitlement?.passes).includes(attractionKey);
}

function getPassQuantities(passes: Pass[]): Record<TicketClass, number> {
  return {
    [TicketClass.Adult]: getPassCountByClass(passes, TicketClass.Adult),
    [TicketClass.Child]: getPassCountByClass(passes, TicketClass.Child),
  };
}

function createEntitlementReservation(
  reservation: Reservation,
  {
    entitlementKey,
    passes,
    productLineup,
    attractionConfigs,
  }: {
    entitlementKey: string;
    passes: Pass[];
    attractionConfigs: AttractionConfig[];
    productLineup: ProductLineup;
  }
): EntitlementReservation {
  const attractionConfig = attractionConfigs.find(
    (a) =>
      a.attractionKey === reservation.attractionKey &&
      a.productLineupKeys.includes(productLineup.productLineupKey)
  );
  if (!attractionConfig) throw new Error('missing attraction config');

  const sortedPasses = sortBy(
    passes.filter(
      (pass) => reservation.barcodes.includes(pass.barcode) && pass.locator === entitlementKey
    ),
    ['barcode', 'ticketClass', 'name']
  );
  const option = getOptions(attractionConfig, productLineup.familyCode).find(
    (o) => o.optionKey === reservation.optionKey
  );
  const ticketCategories = (function getTicketCategories() {
    const ticketCategoriesConfig = attractionConfig?.ticketingSystem?.ticketCategories;
    if (!ticketCategoriesConfig) return [];

    const categories = new Set(
      sortedPasses
        .flatMap((pass) => pass.tickets.map((ticket) => ticket.categoryKey))
        .filter(Boolean)
    );

    return ticketCategoriesConfig
      .reduce<EntitlementReservation['ticketCategories']>((acc, category) => {
        if (categories.has(category.categoryKey)) {
          acc.push(category);
        }
        return acc;
      }, [])
      .sort((a, b) => a.priority - b.priority);
  })();

  return {
    ...reservation,
    entitlementKey,
    type: getReservationTypeByAttractionKey(
      productLineup.attractionGroups,
      reservation.attractionKey
    ),
    passes: sortedPasses,
    attractionConfig,
    productLineup,
    option,
    ticketCategories,
    hasTicketCategories: ticketCategories.length > 0,
  };
}
