import Constants from 'expo-constants';
import * as Device from 'expo-device';
import { locale } from 'expo-localization';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { shallow } from 'zustand/shallow';

import useStore from 'app/hooks/useStore';
import { getBaseURL } from 'app/services/GuestCenterService';
import HeapAnalytics from 'app/services/HeapAnalytics';
import HygraphService from 'app/services/HygraphService';

const hygraphService = HygraphService();

export default function useDebugInfo() {
  const [cmsEnv, cmsStage] = useStore((s) => [s.cmsEnv, s.cmsStage], shallow);
  const [deviceType, setDeviceType] = useState(Device.DeviceType[0]);
  const [heapUserId, setHeapUserId] = useState('');

  useEffect(() => {
    Device.getDeviceTypeAsync().then((type) => setDeviceType(Device.DeviceType[type]));
    HeapAnalytics.getUserId().then((userId) => {
      setHeapUserId(userId);
    });
  }, []);

  function toTitleCase(txt: string): string {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  }

  return {
    commit: Constants.expoConfig?.extra?.commit,
    profile: Constants.expoConfig?.extra?.profile,
    buildid: Constants.expoConfig?.extra?.buildid,
    appVersion: Constants.expoConfig?.version,
    expoVersion: Constants.expoVersion,
    sdkVersion: Constants.expoConfig?.sdkVersion,
    guestCenterTarget: getBaseURL(),
    hygraphApiURL: hygraphService.client.url,
    mode: __DEV__ ? 'development' : 'production',
    platform: Platform.OS,
    platformName: Platform.OS === 'ios' ? 'iOS' : toTitleCase(Platform.OS),
    deviceOS: `${Device.osName} ${Device.osVersion}`,
    deviceType,
    deviceTypeName: toTitleCase(deviceType || ''),
    deviceLanguage: locale.substr(0, 2),
    manufacturer: Device.manufacturer,
    model: Device.modelName,
    sessionId: Constants.sessionId,
    heapUserId,
  };
}
