import { Locale } from 'app/generated/hygraph';
import { ProductContent } from 'app/hooks/useProduct';
import { expandTpl, getLocaleDefaults } from 'app/services/I18n';

export function getFAQs({
  faqs,
  product,
  locale,
}: {
  faqs: ProductContent['faqs'];
  locale: Locale;
  product: Maybe<ProductContent>;
}) {
  const tplProps = {
    props: {
      num_days: product?.validDays ?? 0,
      product_id: product?.key ?? '',
    },
  };
  return (
    faqs?.map((faq) => {
      const { question, answer } = getLocaleDefaults({
        targetLocale: locale,
        localizations: faq.localizations,
      });
      return {
        question: expandTpl(question ?? '', tplProps),
        answer: expandTpl(answer ?? '', tplProps),
      };
    }) ?? []
  );
}
