import { Flex, Heading, HStack, IFlexProps, IHeadingProps, IStackProps } from 'native-base';
import React, { ReactNode } from 'react';

export const VERTICAL_PADDING = '5';

export type IconTitleProps = IFlexProps & {
  title: ReactNode;
  statusNode?: ReactNode;
  icon?: JSX.Element;
  children?: ReactNode;
  padded?: boolean;
  _title?: IHeadingProps;
  _hstack?: IStackProps;
};

export default function IconTitle({
  title,
  statusNode,
  icon,
  children,
  padded,
  _title,
  _hstack,
  ...props
}: IconTitleProps) {
  return (
    <Flex
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      py={padded ? VERTICAL_PADDING : undefined}
      testID="IconTitle"
      {...props}
    >
      <HStack space="2" {..._hstack}>
        {icon}
        {typeof title === 'string' ? (
          <Heading size="sm" {..._title}>
            {title}
          </Heading>
        ) : (
          title
        )}
        {statusNode}
      </HStack>
      {children}
    </Flex>
  );
}
