import 'app/polyfill/native';

import { Box, NativeBaseProvider } from 'native-base';
import { forwardRef, useEffect } from 'react';
import { Platform, StatusBar } from 'react-native';
import { QueryClientProvider } from 'react-query';
import { shallow } from 'zustand/shallow';

import AppDownloadBanner from 'app/components/AppDownloadBanner';
import AppUpdateDialog from 'app/components/AppUpdateDialog';
import DevToolsFAB from 'app/components/DevToolsFAB';
import EntitlementSwitcher from 'app/components/EntitlementSwitcher';
import ErrorBoundary from 'app/components/ErrorBoundary';
import LocaleChangeOverlay from 'app/components/LocaleChangeOverlay/LocaleChangeOverlay';
import ReserveForGlobalHeader from 'app/components/ReserveForGlobalHeader';
import useAnalytics from 'app/hooks/useAnalytics';
import useBootstrap from 'app/hooks/useBootstrap';
import useColorScheme from 'app/hooks/useColorScheme';
import useLinkHandling from 'app/hooks/useLinkHandling';
import useStore from 'app/hooks/useStore';
import RootNavigator from 'app/navigation/RootNavigator';
import AuthProvider from 'app/providers/AuthProvider';
import GuestProvider from 'app/providers/GuestProvider';
import I18nProvider from 'app/providers/I18nProvider';
import RenderHTMLProvider from 'app/providers/RenderHTMLProvider';
import HeapAnalytics from 'app/services/HeapAnalytics';
import QueryClient from 'app/services/QueryClient';
import theme from 'app/theme';
import { MAX_WIDTH_WEB } from 'app/theme/breakpoints';

const queryClient = QueryClient();

/**
 * Main entry point of the app.
 * Must be a child of <Providers/> to consume all the various contexts.
 */
function Main() {
  const { isReady: isLoadingComplete } = useBootstrap();
  const colorScheme = useColorScheme();
  const [colorMode, setColorMode] = useStore((s) => [s.colorMode, s.setColorMode], shallow);

  useLinkHandling();
  useAnalytics();

  useEffect(() => {
    if (colorMode !== 'light') setColorMode('light');
  }, [colorScheme, colorMode, setColorMode]);

  if (!isLoadingComplete) return null;

  return (
    <AppUpdateDialog>
      <LocaleChangeOverlay>
        <RootContainer>
          <RootNavigator
            before={
              Platform.OS === 'web' && (
                <>
                  <ReserveForGlobalHeader />
                  <AppDownloadBanner />
                </>
              )
            }
            colorScheme={colorMode}
          >
            <DevToolsFAB />
          </RootNavigator>
        </RootContainer>
        <EntitlementSwitcher />
        <StatusBar />
      </LocaleChangeOverlay>
    </AppUpdateDialog>
  );
}

function RootContainer({ children }: { children: JSX.Element }) {
  return Platform.OS === 'web' ? (
    <Box
      _web={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: this is valid web style
        style: {
          maxWidth: MAX_WIDTH_WEB,
          marginLeft: 'auto',
          marginRight: 'auto',
          height: '100%',
          width: '100vw',
        },
      }}
    >
      {children}
    </Box>
  ) : (
    children
  );
}

function Providers() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <GuestProvider>
            <I18nProvider>
              <NativeBaseProvider theme={theme}>
                <RenderHTMLProvider>
                  <Main />
                </RenderHTMLProvider>
              </NativeBaseProvider>
            </I18nProvider>
          </GuestProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default HeapAnalytics.withReactNavigationAutotrack(forwardRef(Providers));
