import { Box, Button, Text, VStack } from 'native-base';
import React from 'react';
import { Platform } from 'react-native';

import AlertAction from 'app/components/AlertAction';
import EntitlementsList from 'app/components/EntitlementsList/EntitlementsList';
import Screen from 'app/components/Screen';
import TextLink from 'app/components/TextLink';
import useAuth from 'app/hooks/useAuth';
import useGuest from 'app/hooks/useGuest';
import useGuestDataQuery from 'app/hooks/useGuestDataQuery';
import useGuestDataRefreshControl from 'app/hooks/useGuestDataRefreshControl';
import { RootScreenProps, RootStackParamList } from 'app/navigation/RootNavigator';
import { useI18n } from 'app/providers/I18nProvider';
import Analytics, { Events } from 'app/services/Analytics';
import { tID } from 'app/services/TestHelper';

export type PurchasesScreenProps = RootScreenProps<RootStackParamList, 'Purchases'>;

export default function PurchasesScreen({ navigation, route }: PurchasesScreenProps) {
  const { email, hasEntitlements, entitlements } = useGuest();
  const userData = useGuestDataQuery();
  const { isVerified, isUnverified } = useAuth();
  const { t } = useI18n();
  const { guestDataRefreshControl, guestDataUpdatedAt } = useGuestDataRefreshControl();

  const showSignIn = isUnverified;
  const showAddTickets = !showSignIn && isVerified && !hasEntitlements;
  const showPurchases = !showAddTickets && isVerified && hasEntitlements;
  const showUpdatedAt = showAddTickets || showPurchases;

  const handleExplore = () => {
    navigation.navigate('Destinations');
  };
  const handleSignIn = () => {
    navigation.navigate('Authentication');
  };
  const handleLinkTickets = () => {
    navigation.navigate('LinkPasses');
    Analytics.trackEvent(Events.AuthManualLinkTickets);
  };
  const handleUserDataRefetch = () => {
    userData.refetch();
  };
  const handleEntitlementChange = (newEntitlementKey: string) => {
    setTimeout(() => {
      const productContentKey =
        entitlements.find((ent) => ent.entitlementKey === newEntitlementKey)?.productContentKey ??
        '';
      navigation.replace('Tabs', {
        screen: 'ProductStack',
        params: {
          screen: 'Product',
          params: { productId: productContentKey },
        },
      });
    }, 200);
  };

  return (
    <Screen refreshControl={guestDataRefreshControl} testID="PurchasesScreen">
      {showSignIn && (
        <AlertAction>
          <AlertAction.Title>{t('app_auth_tickets_none_linked_title')}</AlertAction.Title>

          <AlertAction.Body>{t('app_auth_tickets_none_linked')}</AlertAction.Body>

          <AlertAction.Actions>
            <Button testID={tID('ExploreLink')} onPress={handleExplore}>
              {t('gen_explore_destinations')}
            </Button>
          </AlertAction.Actions>

          <AlertAction.Footer>
            <Text>
              {t('app_tickets_already_purchased')}{' '}
              <TextLink testID={tID('SignInLink')} onPress={handleSignIn}>
                {t('app_auth_sign_in')}
              </TextLink>
            </Text>{' '}
          </AlertAction.Footer>
        </AlertAction>
      )}

      {showAddTickets && (
        <AlertAction>
          <AlertAction.Title>{t('gen_link_tickets')}</AlertAction.Title>

          <AlertAction.Body>{t('app_auth_tickets_not_found', { email })}</AlertAction.Body>

          <AlertAction.Actions>
            <Button testID={tID('LinkTickets')} onPress={handleLinkTickets}>
              {t('app_auth_tickets_title')}
            </Button>
          </AlertAction.Actions>

          <AlertAction.Footer>
            <Text fontSize="xs">
              {t('app_welcome_explore_label')}{' '}
              <TextLink onPress={handleExplore}>{t('app_welcome_explore_cta')}</TextLink>
            </Text>
          </AlertAction.Footer>
        </AlertAction>
      )}

      {showPurchases && (
        <Box testID={tID('PurchasesList')}>
          <EntitlementsList onChange={handleEntitlementChange} onLinkTickets={handleLinkTickets} />
        </Box>
      )}

      {showUpdatedAt && (
        <VStack mt="10" space="4">
          {Platform.OS === 'web' && (
            <Button isLoading={userData.isFetching} onPress={handleUserDataRefetch}>
              {t('app_auth_refresh_purchases')}
            </Button>
          )}
          {guestDataUpdatedAt}
        </VStack>
      )}
    </Screen>
  );
}
