import dayjs from 'dayjs';
import { ITextProps, Text } from 'native-base';
import { useMemo } from 'react';
import { RefreshControl } from 'react-native';
import { UseQueryOptions } from 'react-query';

import useConfiguration from 'app/hooks/useConfiguration';
import useContent from 'app/hooks/useContent';
import useGuestDataQuery from 'app/hooks/useGuestDataQuery';
import useProduct from 'app/hooks/useProduct';
import { useI18n } from 'app/providers/I18nProvider';

const notifyOnChangeProps: UseQueryOptions['notifyOnChangeProps'] = [
  'dataUpdatedAt',
  'isRefetchError',
  'isRefetching',
  'refetch',
];

type UseGuestDataRefreshControlProps = {
  productContentKey?: string;
  /** Color of the RefreshControl spinner and text */
  color?: string;
  /** Text props for dataUpdatedAt */
  _text?: ITextProps;
};

export default function useGuestDataRefreshControl({
  color,
  _text,
  productContentKey = '',
}: UseGuestDataRefreshControlProps = {}) {
  const { t } = useI18n();
  const config = useConfiguration({ notifyOnChangeProps });
  const guest = useGuestDataQuery({ notifyOnChangeProps });
  const content = useContent({ notifyOnChangeProps });
  const product = useProduct({ productContentKey, notifyOnChangeProps });
  const queries = useMemo(
    () => [guest, config, content, product],
    [config, guest, content, product]
  );

  const result = useMemo(() => {
    const isRefetching = queries.some((q) => q.isRefetching);
    const isRefetchError = queries.some((q) => q.isRefetchError);
    const dataUpdatedAt = Math.min(...queries.map((q) => q.dataUpdatedAt).filter(Boolean));
    const dataUpdatedAtFmt = dataUpdatedAt
      ? dayjs(dataUpdatedAt).format(' h:mm:ss A, MMMM D, YYYY')
      : '';
    const handleRefetch = () => queries.forEach((q) => q.refetch());

    return {
      guestDataRefreshControl: (
        <RefreshControl refreshing={isRefetching} tintColor={color} onRefresh={handleRefetch} />
      ),
      guestDataUpdatedAt: (
        <Text color={color ?? 'gray.400'} textAlign="center" {..._text}>
          {t('app_updated_last')}
          {'\n'}
          {dataUpdatedAtFmt}
          {'\n'}
          {isRefetchError && (
            <Text color={color} {..._text} textTransform="uppercase">
              {t('app_updated_error')}
            </Text>
          )}
        </Text>
      ),
    };
  }, [_text, color, queries, t]);

  return result;
}
