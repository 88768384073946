import { Actionsheet, Box, Pressable } from 'native-base';
import { ReactNode } from 'react';

import { Cross } from 'app/components/icons';

export type CPActionSheetProps = {
  children?: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
};

export default function CPActionSheet({ children, isOpen, onClose }: CPActionSheetProps) {
  return (
    <Actionsheet hideDragIndicator isOpen={isOpen} onClose={onClose}>
      <Actionsheet.Content px={0}>
        <Box
          alignItems="flex-end"
          borderBottomColor="gray.300"
          borderBottomStyle="solid"
          borderBottomWidth={1}
          display="flex"
          px={3}
          py={2}
          width="100%"
        >
          <Pressable p="1" onPress={onClose}>
            <Cross />
          </Pressable>
        </Box>
        {children}
      </Actionsheet.Content>
    </Actionsheet>
  );
}
