/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */
import { getPathFromState, getStateFromPath, LinkingOptions } from '@react-navigation/native';
import Constants from 'expo-constants';
import * as Linking from 'expo-linking';
import { Platform } from 'react-native';

import { RootStackParamList } from 'app/navigation/RootNavigator';
import Logger from 'app/services/Logger';

export const appURL = (path: string): string =>
  (__DEV__ || Platform.OS !== 'web' ? '' : '') + path;

const webPrefixes =
  Constants.expoConfig?.ios?.associatedDomains
    ?.filter((domain) => /^applinks:[a-z0-9.]+/.test(domain))
    .map((domain) => domain.replace(/^applinks:/, 'https://')) ?? [];

Logger.info('PREFIXES', { webPrefixes });

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/'), ...webPrefixes],
  config: {
    screens: {
      AppInfo: appURL('/info'),
      Authentication: {
        screens: {
          AuthenticationStepEnterEmail: appURL('/welcome'),
          AuthenticationStepVerifyEmail: appURL('/auth/verify'),
          AuthenticationStepTicketLoader: appURL('/auth/loading'),
        },
      },
      Chat: appURL('/chat'),
      Destinations: appURL('/destinations'),
      Feedback: appURL('/feedback'),
      Language: appURL('/language'),
      NotFound: appURL('*'),
      Notifications: appURL('/notifications'),
      Tabs: {
        screens: {
          ProductStack: {
            screens: {
              Product: appURL('/product/:productId'),
              ProductFAQ: appURL('/product/:productId/faq'),
              ProductOffers: appURL('/product/:productId/offers'),
            },
          },
          AttractionTicketsStack: {
            screens: {
              AttractionTickets: appURL('/product/:productId/attractions'),
              Attraction: appURL('/product/:productId/attraction/:attractionId'),
            },
          },
          Menu: appURL('/menu'),
        },
      },
      Purchases: appURL('/purchases'),
      Profile: appURL('/profile'),
      ReservationFlow: appURL('/make-reservation'),
      TermsConditions: appURL('/terms-conditions'),
      LinkPasses: appURL('/auth/link'),
    },
  },
  // getStateFromPath(path, config) {
  //   // Return a state object here
  //   // You can also reuse the default logic by importing `getStateFromPath` from `@react-navigation/native`
  //   console.log('PATH', path, config);
  //   return getStateFromPath(path, config);
  // },
  // getPathFromState(state, config) {
  //   // Return a state object here
  //   // You can also reuse the default logic by importing `getStateFromPath` from `@react-navigation/native`
  //   console.log('STATE', state, config);
  //   return getPathFromState(state, config);
  // },
};

export default linking;
