import { createNavigationContainerRef } from '@react-navigation/native';
import get from 'lodash/get';
import { Platform } from 'react-native';

import useRootNavigation from 'app/hooks/useRootNavigation';
import { RootStackParamList } from 'app/navigation/RootNavigator';

export const navigationRef = createNavigationContainerRef<RootStackParamList>();

export function navigate(name: keyof RootStackParamList, props?: Record<string, unknown>) {
  if (navigationRef.isReady()) {
    // TODO: how to get this massive union type instead of using any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigationRef.navigate(name as any, props);
  }
}

export function getCurrentRoute(navigation: ReturnType<typeof useRootNavigation>) {
  const state = navigation.getState();
  return state.routes[state.routes.length - 1];
}

export function getCurrentRouteParams(navigation: ReturnType<typeof useRootNavigation>) {
  return getCurrentRoute(navigation).params as unknown as Maybe<Record<string, Maybe<string>>>;
}

export function getRouteParam(route: unknown, param: string): string | undefined {
  return (
    get(route, ['params', param]) ||
    get(route, ['params', 'params', param]) ||
    get(route, ['params', 'params', 'params', param]) ||
    get(route, ['params', 'params', 'params', 'params', param])
  );
}

type SimpleNavState = {
  index?: number;
  params?: { productId?: string };
  state?: { routes: SimpleNavState[]; index?: number };
};

export function findProductId(state: SimpleNavState) {
  const productIdFromNavState = findProductIdFromState({ state }, state.index);
  if (productIdFromNavState) return productIdFromNavState;

  const productIdFromPath = findProductIdFromPath();
  if (productIdFromPath) return productIdFromPath;

  return undefined;
}

function findProductIdFromState(ctx: any, routeIndex = 0): string | undefined {
  if (typeof ctx !== 'object' || ctx === null || Array.isArray(ctx)) {
    return undefined;
  }
  if (ctx?.params?.productId) {
    return ctx.params.productId;
  }
  if (ctx?.state?.routes?.[routeIndex]) {
    return findProductIdFromState(ctx.state.routes[routeIndex]);
  }
  return undefined;
}

function findProductIdFromPath() {
  if (Platform.OS === 'web') {
    const [_, pathProductId] =
      window.location.pathname.match(/product\/([\w-]+?)(?:$|\?|\/)/) ?? [];
    if (pathProductId) return pathProductId;
  }

  return undefined;
}
