const colors = {
  brand: {
    primary: '#e51937',
    secondary: '#373737',
    border: '#ddd',
    info: '#7DD3FC',
    warning: '#FFD87E',
    error: '#B50323',
    success: '#86EFAC',
    toast: '#0369A1',
    popover: '#BAE6FD',
  },
  primary: {
    100: '#FBDDDF',
    200: '#FBDDDD',
    300: '#F6ACAD',
    400: '#F07B82',
    500: '#EB4A5B',
    600: '#e51937',
    700: '#C91530',
    800: '#AD1229',
    900: '#900F22',
  },
  secondary: {
    100: '#777777',
    200: '#676767',
    300: '#575757',
    400: '#474747',
    500: '#373737',
    600: '#272727',
    700: '#171717',
    800: '#070707',
    900: '#000',
  },
  darkSecondary: {
    100: '#fff',
    200: '#fff',
    300: '#fff',
    400: '#fff',
    500: '#fff',
    600: '#fff',
    700: '#fff',
    800: '#fff',
    900: '#fff',
  },
};

export default colors;
