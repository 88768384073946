import get from 'lodash/get';
import { ITextProps, useTheme } from 'native-base';
import React, { useMemo } from 'react';
import { TextProps, useWindowDimensions } from 'react-native';
import RenderHTML, { RenderHTMLProps } from 'react-native-render-html';

import { useRenderHTMLContext } from 'app/providers/RenderHTMLProvider';

export type HTMLRendererProps = Pick<
  ITextProps,
  'bold' | 'color' | 'fontSize' | 'underline' | 'textAlign'
> & {
  html: Maybe<string>;
  defaultTextProps?: TextProps;
  lineHeight?: number;
  renderersProps?: RenderHTMLProps['renderersProps'];
};

export default function HTML({
  html = '',
  defaultTextProps,
  renderersProps,
  ...props
}: HTMLRendererProps) {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const renderHTMLProps = useRenderHTMLContext();
  const sanitized = useMemo(() => html?.trim(), [html]);

  if (props.color || props.bold || props.fontSize || props.underline || props.textAlign) {
    // eslint-disable-next-line no-param-reassign
    defaultTextProps = defaultTextProps || {};
    // eslint-disable-next-line no-param-reassign
    defaultTextProps.style = defaultTextProps.style || {};
    Object.assign(defaultTextProps.style, {
      textAlign: props.textAlign ? props.textAlign : 'left',
      color: props.color ? get(theme.colors, props.color as string) || props.color : undefined,
      fontSize: props.fontSize ? Number(get(theme.fontSizes, props.fontSize as string)) : undefined,
      textDecorationLine: props.underline ? 'underline' : undefined,
      ...(props.bold && {
        fontWeight: 'normal',
        fontFamily: 'ProximaNova-Bold',
      }),
      ...(props.lineHeight && { lineHeight: props.lineHeight }),
    });
  }

  if (!sanitized) return null;

  return (
    <RenderHTML
      {...renderHTMLProps}
      contentWidth={width}
      defaultTextProps={defaultTextProps}
      renderersProps={{ ...renderHTMLProps.renderersProps, ...renderersProps }}
      source={{ html: sanitized }}
    />
  );
}
