/* eslint-disable no-underscore-dangle */

import Menu, { MenuItem } from 'native-base/src/theme/components/menu';

type Components = Record<
  string,
  {
    baseStyle?: Record<string, any>;
    defaultProps?: Record<string, any>;
    variants?: Record<string, any>;
  }
>;

const bgModal = 'white';

// https://docs.nativebase.io/next/customizing-components
const components: Components = {
  Alert: {
    baseStyle: {
      p: 4,
    },
  },
  AlertDialog: {
    defaultProps: {
      size: 'lg',
    },
  },
  AlertDialogFooter: {
    baseStyle: {
      borderTopWidth: 0,
      flexDirection: 'column',
      borderColor: 'black',
      alignItems: 'stretch',
      flexWrap: 'nowrap',
      justifyItems: 'stretch',
    },
  },
  AlertDialogHeader: {
    baseStyle: {
      borderBottomWidth: 0,
      pb: 1,
      _text: {
        textAlign: 'center',
        fontSize: 'lg',
        fontWeight: 'bold',
      },
    },
  },
  Button: {
    baseStyle: {
      shadow: 2,
      rounded: 'full',
      _text: {
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: 'md',
      },
      _loading: {
        opacity: 0.8,
      },
      _disabled: {
        opacity: 1,
        bg: 'trueGray.400',
      },
      minH: '48px',
    },
    defaultProps: {
      px: 6,
      py: 2.5,
      colorScheme: 'secondary',
    },
    variants: {
      solid: {
        _text: {
          color: 'white',
        },
        // inherited opacity makes the text almost invisible
        // https://github.com/facebook/react-native/issues/24128#issue-424652178
        _android: {
          _disabled: {
            opacity: 1,
            bg: 'trueGray.400',
          },
        },
      },
      link: {
        shadow: 0,
        _text: {
          textDecorationLine: 'underline',
        },
        _light: {
          _text: {
            fontWeight: 'bold',
            color: 'black',
            textDecorationColor: 'black',
          },
          _disabled: {
            _text: {
              color: 'muted.100',
            },
          },
        },
        _android: {
          _light: {
            bg: 'white',
            shadow: '0 !important',
          },
        },
        _web: {
          _light: {
            shadow: '0 !important',
          },
        },
      },
      outline: {
        borderWidth: 2,
        bgColor: 'white',
        _light: {
          borderColor: 'black',
        },
        _text: {
          color: 'black',
        },
      },
      linkInverted: {
        shadow: 0,
        _text: {
          textDecorationLine: 'underline',
        },
        _light: {
          _spinner: { color: 'white' },
          _text: { color: 'white' },
        },
        _android: {
          _light: {
            bg: 'rgba(255,255,255,0)',
            shadow: '0 !important',
          },
        },
        _web: {
          _light: {
            shadow: '0 !important',
          },
        },
      },
      solidInverted: {
        _light: {
          bg: 'white',
          _pressed: { bg: 'gray.200', _text: { color: 'black' } },
          _text: { color: 'black' },
        },
      },
      ghost: {
        borderWidth: 2,
        borderColor: 'white',
        _light: {
          _text: {
            fontSize: 'xl',
            color: 'white',
          },
        },
      },
    },
  },
  ButtonGroup: {
    defaultProps: {
      direction: 'column',
      space: 3,
    },
  },
  FormControlLabel: {
    baseStyle: {
      _text: {
        color: 'black',
      },
    },
  },
  Input: {
    baseStyle: {
      _light: {
        _focus: {
          borderColor: 'black',
        },
      },
    },
    defaultProps: {
      variant: 'underlined',
      size: 'lg',
    },
    variants: {
      underlined: {
        color: 'black',
        placeholderTextColor: 'muted.700',
      },
      underlinedInverted: {
        borderWidth: '0',
        pl: '0',
        borderBottomWidth: '1',
        rounded: 'none',
        _light: {
          color: 'text.50',
          placeholderTextColor: 'muted.700',
          borderColor: 'muted.500',
          _focus: {
            borderColor: 'white',
          },
        },
      },
    },
  },
  Link: {
    defaultProps: {
      _text: {
        fontWeight: 'bold',
      },
    },
  },
  Menu: {
    baseStyle: {
      _light: Menu.baseStyle._dark,
      _dark: Menu.baseStyle,
    },
  },
  MenuItem: {
    baseStyle: {
      _light: MenuItem.baseStyle._dark,
      _dark: MenuItem.baseStyle,
      _text: {
        color: 'gray.50',
      },
    },
  },
  Modal: {
    defaultProps: {
      size: 'lg',
    },
  },
  ModalContent: {
    baseStyle: {
      rounded: '2xl',
      pt: 6, // push content below close button
      _light: {
        bg: bgModal,
      },
    },
  },
  ModalBody: {
    baseStyle: {
      bg: bgModal,
    },
  },
  ModalHeader: {
    baseStyle: {
      borderBottomWidth: 0,
      pb: 1,
      _text: {
        textAlign: 'center',
        fontSize: 'lg',
        fontWeight: 'bold',
      },
      _light: {
        bg: bgModal,
      },
    },
  },
  ModalFooter: {
    baseStyle: {
      borderTopWidth: 0,
      _light: {
        bg: bgModal,
      },
    },
  },
  PopoverArrow: {
    baseStyle: {
      _light: {
        bg: 'brand.popover',
      },
    },
  },
  PopoverBody: {
    baseStyle: {
      rounded: 'lg',
      shadow: '7',
      _light: {
        bg: 'brand.popover',
      },
    },
  },
  PopoverContent: {
    baseStyle: {
      mx: 4,
      overflow: 'visible',
      shadow: '1',
      borderWidth: 0,
      _web: {
        maxWidth: '575px',
      },
    },
  },
  PopoverCloseButton: {
    baseStyle: {
      _light: {
        _hover: { bg: 'brand.popover' },
        _pressed: { opacity: 0.75, bg: 'brand.popover' },
        _icon: { color: 'brand.secondary' },
      },
    },
  },
  Radio: {
    defaultProps: {
      borderColor: 'brand.border',
      _icon: {
        color: 'white',
      },
      _checked: {
        bgColor: 'brand.primary',
      },
      _pressed: {
        borderColor: 'gray.100',
      },
    },
  },
  Select: {
    baseStyle: {
      py: 4,
      px: 2,
      fontSize: 'sm',
      fontWeight: 'bold',
      borderWidth: 2,
      borderBottomWidth: 2,
      rounded: 'md',
      overflow: 'visible',
      _light: {
        borderColor: 'black',
        placeholderTextColor: 'black',
      },
      _android: {
        py: 3,
      },
    },
    defaultProps: {
      variant: 'outline',
      _item: {
        rounded: 'md',
        _pressed: { bgColor: 'gray.300' },
      },
      _selectedItem: {
        bgColor: 'gray.200',
      },
    },
  },
  SelectItem: {
    baseStyle: {
      _text: {
        color: 'red.500',
      },
    },
  },
  Spinner: {
    baseStyle: {
      color: 'gray.500',
    },
  },
  Toast: {
    baseStyle: {
      bg: 'brand.toast',
      minW: '1/3',
      px: 4,
      _stack: {
        px: 6,
      },
      _title: { textAlign: 'center' },
      _description: { textAlign: 'center' },
    },
    variants: {
      success: {
        bg: 'success.600',
      },
    },
  },
};

export default components;
